import {
  Button,
  Col,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import VacancyData from './VacancyData';
import vacancyStore from '../state/VacancyStore';
import ApiDiContainer from '../apiService/apiDiContainer';
import currentUserStore from '../state/CurrentUserStore';
import AddRelationVacancyApplicant from './AddRelationVacancyApplicant';
import moment from 'moment/moment';
import { withRouter } from '../WithRouter';

class ModalVacancy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedRowKeys: [],
      treeData: [],
      selectedArray: [],
    };
  }

  render() {
    const requiredFields = [
      'title',
      'department',
      'salaryMin',
      'salaryMax',
      'workExperienceMin',
      'workExperienceMax',
      'description',
      'applicantRequirements',
      'createdBy',
      'numToHire',
      'workSchedule',
    ];
    return (
      <>
        <Modal
          title="Создать заявку на вакансию"
          width={'80vw'}
          open={this.props.isShow}
          onOk={() => {
            this.props.isHidden();
            this.getWholeData();
          }}
          onCancel={() => {
            this.props.isHidden();
          }}
          footer={[
            <Button>Отменить</Button>,
       
            <Button
              type="primary"
              onClick={() => {
                console.log(vacancyStore.checkRequired(requiredFields))
                if (!vacancyStore.checkRequired(requiredFields)) {
                  message.info('Заполните обязательные поля');
                  return 
                }
                ApiDiContainer.ProxyApiAction.addVacancy({
                  ...vacancyStore.getObject(),
                  department: vacancyStore.getVariable('department'),
                  status: {
                    id: 2,
                  },
                  workSchedule:vacancyStore.getVariable('workSchedule'),
                  lastUpdated: moment(),
                  numToHire: vacancyStore.getVariable('numToHire'),
                  isArchive: false,
                  createdBy: currentUserStore.getVariable('id'),
                  recruiter: '',
                }).then(() => {
                  message.success('Вакансия создана');
                  this.props.isHidden();
                })
              
              }}>
              Отправить
            </Button>,
          ]}>
          <VacancyData />
        </Modal>
      </>
    );
  }
}
export default withRouter(ModalVacancy);
