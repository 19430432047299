import React from 'react';

import { PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';

import { Button, Card, Input, Select, Space, Table } from 'antd';

import Title from 'antd/es/typography/Title';
import FileSaver from 'file-saver';
import { observer } from 'mobx-react';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../WithRouter';
import ApiDiContainer from '../apiService/apiDiContainer';

import { get, isEqual } from 'lodash';
class ReportPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedRowKeys: [],
      treeData: [],
      selectedArray: [],
      dataApp: [],
      searchText: '',
      searchedColumn: '',
    };
  }

  componentDidMount() {
    this.getAllVacancy({});
    this.getApplicant();
  }

  getAllVacancy(filters) {
    ApiDiContainer.ProxyApiAction.getAllVacancy(filters).then((res) => {
      this.setState({ data: res.content });
    });
  }

  getApplicant() {
    ApiDiContainer.ProxyApiAction.postAllApplicants({}).then((res) => {
      this.setState({ dataApp: res.content });
    });
  }

  ///фильрты таблица (ФИЛЬТРЫ ДЛЯ ТАБЛИЦЫ ОБЩИЕ)
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Поиск`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Поиск
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Сбросить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        ? get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      isEqual(this.state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#89c6ff', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  render() {
    console.log(this.state);
    return (
      <Card style={{ margin: 20 }}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Title style={{ margin: 0 }}>Отчеты</Title>
                </div>
                <div style={{ display: 'flex', marginBottom: 20 }}>
                  <Select
                    placeholder="тип отчета"
                    style={{ width: '100%' }}
                    onChange={(e, obj) => {
                      this.setState({ currentTable: obj, selectedRowKeys: [] });
                    }}
                    options={[
                      { id: 1, value: 'Вакансии', label: 'Вакансии' },
                      { id: 2, value: 'Кандидаты', label: 'Кандидаты' },
                    ]}
                  />
                  {this.state?.currentTable?.value === 'Вакансии' && (
                    <Button
                      disabled={!this.state.selectedRowKeys.length > 0}
                      onClick={() => {
                        ApiDiContainer.ProxyApiAction.exportVacancy(
                          this.state.selectedRowKeys,
                        ).then((res) => {
                          console.log(res);
                          let blob = new Blob([res.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          });

                          FileSaver.saveAs(blob, 'vacancy.xlsx');
                        });
                      }}
                      type="primary"
                      style={{ marginLeft: 20 }}
                      icon={<PlusSquareOutlined />}>
                      Скачать Exel
                    </Button>
                  )}

                  {this.state?.currentTable?.value === 'Кандидаты' && (
                    <Button
                      disabled={!this.state.selectedRowKeys.length > 0}
                      onClick={() => {
                        ApiDiContainer.ProxyApiAction.exportApplicant(
                          this.state.selectedRowKeys,
                        ).then((res) => {
                          console.log(res);
                          let blob = new Blob([res.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          });

                          FileSaver.saveAs(blob, 'applicant.xlsx');
                        });
                      }}
                      type="primary"
                      icon={<PlusSquareOutlined />}>
                      Скачать Exel
                    </Button>
                  )}
                </div>

                {this.state?.currentTable?.value === 'Вакансии' && (
                  <Table
                    rowSelection={{
                      type: 'checkbox',

                      onChange: (selectedRowKeys, row) => {
                        this.setState({ selectedRowKeys });
                      },
                      selectedRowKeys: this.state.selectedRowKeys,
                      preserveSelectedRowKeys: false,
                    }}
                    rowKey={'id'}
                    dataSource={this.state.data}
                    columns={[
                      {
                        dataIndex: 'title',
                        title: 'Вакансия',
                        ...this.getColumnSearchProps('title'),
                      },
                      {
                        dataIndex: 'department',
                        title: 'Департамент',
                        ...this.getColumnSearchProps('department'),
                      },
                      {
                        dataIndex: 'numToHire',
                        title: 'Кол-во позиций',
                        ...this.getColumnSearchProps('numToHire'),
                      },
                      {
                        dataIndex: ['status', 'title'],

                        title: 'Статус',
                        ...this.getColumnSearchProps(['status', 'title']),
                      },
                      {
                        dataIndex: 'createdBy',
                        title: 'Инициатор',
                        ...this.getColumnSearchProps('createdBy'),
                      },
                      {
                        dataIndex: 'lastUpdated',
                        title: 'Дата',
                        render: (e) => moment(e).format('LLL'),
                      
                      },
                    ]}
                  />
                )}

                {this.state?.currentTable?.value === 'Кандидаты' && (
                  <Table
                    rowSelection={{
                      type: 'checkbox',

                      onChange: (selectedRowKeys, row) => {
                        this.setState({ selectedRowKeys });
                      },
                      selectedRowKeys: this.state.selectedRowKeys,
                      preserveSelectedRowKeys: false,
                    }}
                    rowKey={'id'}
                    dataSource={this.state.dataApp}
                    columns={[
                      {
                        dataIndex: 'fio',
                        title: 'ФИО',
                        render: (text, record) => {
                          return (
                            <div>
                              <div>
                                {record.lastName} {record.firstName} {record.middleName}
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        dataIndex: 'age',
                        title: 'Возраст',
                        sorter: (a, b) => {
                          return a.age - b.age;
                        },

                        ...this.getColumnSearchProps('age'),
                      },
                      {
                        dataIndex: 'phoneNumber',
                        title: 'Телефон',
                        ...this.getColumnSearchProps('phoneNumber'),
                      },
                      {
                        dataIndex: 'email',
                        title: 'Email',
                        ...this.getColumnSearchProps('email'),
                      },
                      {
                        dataIndex: 'applicantSource',
                        title: 'Источник',
                        ...this.getColumnSearchProps(['applicantSource', 'name']),
                        render: (text, record) => {
                          return (
                            <div>
                              <div>{record.applicantSource.name}</div>
                            </div>
                          );
                        },
                      },
                    ]}
                  />
                )}
              </>
            }
          />
        </Routes>
      </Card>
    );
  }
}
export default observer(withRouter(ReportPage));
