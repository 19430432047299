import { Button, Col, Input, InputNumber, Modal, Radio, Row, Select, Space, Table, Tag, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import currentUserStore from '../state/CurrentUserStore';
import { toJS } from 'mobx';
import vacancyStore from '../state/VacancyStore';
import { observer } from 'mobx-react';
import ApiDiContainer from '../apiService/apiDiContainer';
import { get, isEqual } from 'lodash';
import Highlighter from 'react-highlight-words';
import { EyeOutlined, EditOutlined ,SearchOutlined} from '@ant-design/icons';
class AddRelationVacancyApplicant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedRowKeys: [],
      treeData:[],
      selectedArray:[],
      searchText: '',
      searchedColumn: '',
    };
  }
  componentDidMount(){
    ApiDiContainer.ProxyApiAction.getAllApplicant().then((res)=>{
      this.setState({data:res.content})
    })
  }

  ///фильрты таблица (ФИЛЬТРЫ ДЛЯ ТАБЛИЦЫ ОБЩИЕ)
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Поиск`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Поиск
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Сбросить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        ? get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      isEqual(this.state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#89c6ff', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  render() {
    console.log(this.state)
    console.log(toJS(vacancyStore))
    return (
      <>
       
          <Table
          
          rowSelection={{
            type: 'checkbox',

            onChange: (selectedRowKeys, row) => {
              this.setState({ selectedRowKeys });
              vacancyStore.updateVariable('selectedApplicant',selectedRowKeys)
            },
            selectedRowKeys: vacancyStore.getVariable('selectedApplicant')||[],
            preserveSelectedRowKeys: false,
          }}
          rowKey={'id'}
          dataSource={this.state.data}

          columns={[
            {
              dataIndex: 'lastName',
              title: 'Фамилия',
              ...this.getColumnSearchProps('lastName')
            },
            {
              dataIndex: 'firstName',
              title: 'Имя',
              ...this.getColumnSearchProps('firstName')
            },
          
          
         
            {
              dataIndex: 'lastJobTitle',
              title: 'Последнее место работы',
              ...this.getColumnSearchProps('lastJobTitle')
         
            },
            
          ]}
          />
         

         
  
      </>
    );
  }
}
export default observer(AddRelationVacancyApplicant);
