// import { notification } from 'antd';
import { notification } from 'antd';
import axios from 'axios';
import axiosRetry from 'axios-retry';
// window.externalConfigChecked = false; // flag for externalized config check
// window.externalConfigAlerted = false;
axiosRetry(axios, {
  retries: 5,
});
const headersForSearch = new Headers({
  'content-type': 'application/json',
  Authorization: 'Basic ' + btoa('asdf:ewevvyBwfhtGNDCpPfnXAj'),
});



function makeUrls() {
  // await getExternalConfig();
  //console.log('External api config provided. Will use external config', window.externalConfig);

  return (
    window._env_.MAINAPI_HOST +
    '://' +
    window._env_.MAINAPI_SERVER +
    (window._env_.MAINAPI_PORT.length > 0 ? ':' + window._env_.MAINAPI_PORT + '/' : '/') +
    window._env_.MAINAPI_API +
    '/'
  );
}

function makeUrlsForSearch() {
  return (
    window._env_.SEARCHAPI_HOST +
    '://' +
    window._env_.SEARCHAPI_SERVER +
    (window._env_.SEARCHAPI_PORT.length > 0
      ? ':' + window._env_.SEARCHAPI_PORT.length + '/'
      : '/') +
    window._env_.SEARCHAPI_API +
    '/'
  );
}

function makeUrlsForRedirectToSearch() {
  return (
    window._env_.SEARCHAPI_HOST +
    '://' +
    window._env_.SEARCHAPI_SERVER +
    (window._env_.SEARCHAPI_PORT.length > 0 ? ':' + window._env_.SEARCHAPI_PORT + '/' : '/') +
    (window._env_.SEARCHAPI_GUIURLPREFIX.length > 0
      ? window._env_.SEARCHAPI_GUIURLPREFIX + '/'
      : '')
  );
}

function makeUrlsForRedirectToDiffer() {
  return (
    window._env_.DIFFERAPI_HOST +
    '://' +
    window._env_.DIFFERAPI_SERVER +
    (window._env_.DIFFERAPI_PORT.length > 0 ? ':' + window._env_.DIFFERAPI_PORT + '/' : '/') +
    (window._env_.DIFFERAPI_GUIURLPREFIX.length > 0
      ? window._env_.DIFFERAPI_GUIURLPREFIX + '/'
      : '')
  );
}

function makeUrlsForRedirectToSimilar() {
  return (
    window._env_.SIMILARAPI_HOST +
    '://' +
    window._env_.SIMILARAPI_SERVER +
    (window._env_.SIMILARAPI_PORT.length > 0 ? ':' + window._env_.SIMILARAPI_PORT + '/' : '/') +
    (window._env_.SIMILARAPI_GUIURLPREFIX.length > 0
      ? window._env_.SIMILARAPI_GUIURLPREFIX + '/'
      : '')
  );
}

class RestClient {
  // static get(url) {
  //   return fetch(makeUrls() + url, {
  //     headers: {
  //       Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
  //     },
  //   })
  //     .then((response) => response.json())
  //     .catch(
  //       (err) => ({}),
  //       // notification.error({
  //       //   message: err.message,
  //       // }),
  //     );
  // }

  static getFile(url) {
    return fetch(makeUrls() + url, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
      },
    });
  }
  static get(url,adress='https://hr.backend.api.flareon.ru') {
    const config = {
      method: 'get',
      retries: 5,
      url: adress + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        PROXY_ADDRESS_FORWARDING: "true"
      },
    };
    axiosRetry(axios, { retries: 3 });
    return axios(config).then((res) => res.data);
  }

  // static get(url, updateProgress) {
  //   // return api.get(window._env_.OGANES_SERVER + url).json();
  //   return ky
  //     .get(window._env_.OGANES_SERVER + url, {
  //       headers: {
  //         Authorization: 'Bearer ' + sessionStorage.getItem('token'),
  //       },
  //       onDownloadProgress: (progress, chunk) => {
  //         // Example output:
  //         // `0% - 0 of 1271 bytes`
  //         // `100% - 1271 of 1271 bytes`
  //         updateProgress && updateProgress(progress.percent * 100);
  //         // console.log(
  //         //   `${progress.percent * 100}% - ${progress.transferredBytes} of ${
  //         //     progress.totalBytes
  //         //   } bytes`,
  //         // );
  //       },
  //     })
  //     .json();
  // }


  static post(url, id,adress='https://hr.backend.api.flareon.ru') {
    const config = {
      retries: 5,
      method: 'post',
      url: adress + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        PROXY_ADDRESS_FORWARDING: "true"
      },
      data: id,
    };
    return axios(config).then((res) => res.data);
  }

  static postBlob(url, id,adress='https://hr.backend.api.flareon.ru') {
    const config = {
      method: 'post',
      responseType: 'blob',
      url: adress + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
      data: id,
    };
    return axios(config);
  }
  static getBlob(url) {
    const config = {
      method: 'get',
      responseType: 'blob',
      url: window._env_.OGANES_SERVER + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
       
      },
     
    };
    return axios(config);
  }


  static getAxiosKeyCloak(url) {
    const config = {
      method: 'get',
      url: `${window._env_.KEYCLOACK_ARM + url}`,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
    };
    return axios(config);
  }

  static postAxiosKeyCloak(url, id) {
    const config = {
      method: 'post',
      url: `${window._env_.KEYCLOACK_ARM + url}`,

      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
      data: id,
    };
    return axios(config);
  }
  static patchAxiosKeyCloak(url, id) {
    const config = {
      method: 'patch',
      url: `${window._env_.KEYCLOACK_ARM + url}`,

      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
      data: id,
    };
    return axios(config);
  }

  static async deleteAxiosKeyCloak(url, id) {
    const config = {
      method: 'delete',
      url: `${window._env_.KEYCLOACK_ARM + url}`,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
      data: id,
    };
    return axios(config);
  }

  static getAxios(url, path = `${makeUrls()}`) {
    const config = {
      method: 'get',
      url: path + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
    };
    return axios(config);
  }

  static getFetchAuth(url) {
    return fetch(`${makeUrls() + url}`, { credentials: 'include' });
  }

  static getAxiosBlob(url) {
    const config = {
      method: 'get',
      responseType: 'blob',
      url: `${makeUrls() + url}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
        'Access-Control-Expose-Headers': 'Content-Disposition',
      },
    };
    return axios(config);
  }

  static postAxiosBlob(url, id) {
    const config = {
      method: 'post',
      responseType: 'blob',
      url: `${makeUrls() + url}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
      },
      data: id,
    };
    return axios(config);
  }

  static postAxiosBlobYar(url, id, formatFile) {
    let config = {};
    id.length > 0
      ? (config = {
          method: 'post',
          responseType: 'blob',
          url: `${makeUrls() + url}`,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
            Format: formatFile,
          },
          data: id,
        })
      : (config = {
          method: 'post',
          responseType: 'blob',
          url: `${makeUrls() + url}`,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
            Format: formatFile,
          },
        });
    return axios(config);
  }


  static postAxios(url, id, path = `${makeUrls()}`) {
    const config = {
      method: 'post',
      url: path + url,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
      data: id,
    };
    return axios(config);
  }
  static patchAxios(url, id) {
    const config = {
      method: 'patch',
      url: `${makeUrls() + url}`,

      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
      },
      data: id,
    };
    return axios(config);
  }

  static async putAxios(url, id) {
    const config = {
      method: 'put',
      url: `${makeUrls() + url}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
      },
      data: id,
    };
    return axios(config);
  }

  static async deleteAxios(url, id) {
    const config = {
      method: 'delete',
      url: `${makeUrls() + url}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
      },
      data: {},
    };
    return axios(config);
  }

  static postMultipartAxios(url, data, id) {
    console.log(`are you here`, url, data, id);
    const config = {
      method: 'post',
      url: `${makeUrls() + url}`,
      // url: 'http://94.250.250.171:80/udp-client/files/upload-file-fnp',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
        fnpId: id,
      },
      // headers: {
      //
      //   'Content-Type': 'multipart/form-data',
      //   Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
      //   ...data.getHeaders(),
      // },
      data: data,
    };

    return axios(config);
  }
  static postMultipartAxiosAction(url, data, id) {
    console.log(`are you here`, url, data, id);
    const config = {
      method: 'post',
      url: `${makeUrls() + url}`,
      // url: 'http://94.250.250.171:80/udp-client/files/upload-file-fnp',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
        actionId: id,
      },
      // headers: {
      //
      //   'Content-Type': 'multipart/form-data',
      //   Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
      //   ...data.getHeaders(),
      // },
      data: data,
    };

    return axios(config);
  }

  static postMultipartTdDocAxios(url, data, id) {
    console.log(`are you here`, url, data, id);
    const config = {
      method: 'post',
      url: `${makeUrls() + url}`,
      // url: 'http://94.250.250.171:80/udp-client/files/upload-file-fnp',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
        docId: id,
      },
      // headers: {
      //
      //   'Content-Type': 'multipart/form-data',
      //   Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
      //   ...data.getHeaders(),
      // },
      data: data,
    };

    return axios(config);
  }

  //For Another Server Connection
  static getSearch(url) {
    return fetch(makeUrlsForSearch() + url, {
      method: 'get',
      headers: headersForSearch,
    }).then((response) => response.json());
  }

  static postSearch(url, body) {
    return fetch(makeUrlsForSearch() + url, {
      method: 'post',
      mode: 'cors',
      headers: headersForSearch,
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static getFileName(response) {
    const fileNameStartIndex = response.indexOf("''") + 2;
    return decodeURI(response.slice(fileNameStartIndex));
  }

  static getSearchUrl() {
    return makeUrlsForSearch();
  }

  static getSearchRedirectUrl() {
    return makeUrlsForRedirectToSearch();
  }

  static getSimilarRedirectUrl() {
    return makeUrlsForRedirectToSimilar();
  }

  static postMultipartReportUpload(url, file, id) {
    const config = {
      method: 'post',
      url: `${makeUrls() + url}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('authenticatedToken'),
        reportId: id,
      },
      data: file,
    };

    return axios(config);
  }
}

export default RestClient;
