import React from 'react';

import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Radio, Table } from 'antd';

import FileSaver from 'file-saver';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../WithRouter';
import ApiDiContainer from '../apiService/apiDiContainer';
import ApplicantData from './ApplicantData';

class ApplicantTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [], listOfStages: [], currentStage: {}, selectedRowKeys: [] };
  }
  componentDidMount() {
    this.getApplicantByStageAndVacancy();
    this.postAllStages();
  }

  getApplicantByStageAndVacancy(stage) {
    ApiDiContainer.ProxyApiAction.getApplicantByStageAndVacancy(
      stage || this.state.currentStage?.id || '',
      this.props.params.id,
    ).then((res) => this.setState({ data: res }));
  }

  getApplicantByStageAndVacancyCustom(stage) {
    ApiDiContainer.ProxyApiAction.getApplicantByStageAndVacancy(
      stage,
      this.props.params.id,
    ).then((res) => this.setState({ data: res }));
  }
  postAllStages() {
    ApiDiContainer.ProxyApiAction.postAllStages({}).then((res) =>
      this.setState({ listOfStages: res.content.sort((a,b)=>a.id -(b.id)) }),
    );
  }
  render() {
    return (
      <>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 25 }}>
                  <Radio.Group defaultValue={{}} buttonStyle="solid">
                    <Radio.Button
                      onChange={(el, b) => {
                        this.setState({ currentStage: {} });
                        this.getApplicantByStageAndVacancyCustom('');
                      }}>
                      Все вакансии
                    </Radio.Button>
                    {this.state.listOfStages.map((el) => {
                      return (
                        <Radio.Button
                          value={el}
                          onChange={(el, b) => {
                            this.setState({ currentStage: el.target.value });
                            this.getApplicantByStageAndVacancy(el.target.value.id);
                          }}>
                          {el.name}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>

                  <Button
                    disabled={!this.state.selectedRowKeys.length > 0}
                    onClick={() => {
                      ApiDiContainer.ProxyApiAction.exportApplicant(
                        this.state.selectedRowKeys,
                      ).then((res) => {
                        console.log(res);
                        let blob = new Blob([res.data], {
                          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        });

                        FileSaver.saveAs(blob, 'applicant.xlsx');
                      });
                    }}
                    type="primary"
                    icon={<PlusSquareOutlined />}>
                    Скачать Exel
                  </Button>
                </div>
                <Table
                  dataSource={this.state.data}
                  rowSelection={{
                    type: 'checkbox',

                    onChange: (selectedRowKeys, row) => {
                      this.setState({ selectedRowKeys });
                    },
                    selectedRowKeys: this.state.selectedRowKeys,
                    preserveSelectedRowKeys: false,
                  }}
                  rowKey={'id'}
                  columns={[
                    {
                      dataIndex: ['applicant', 'lastName'],
                      title: 'Фамилия',
                    },
                    {
                      dataIndex: ['applicant', 'middleName'],
                      title: 'Отчество',
                    },
                    {
                      dataIndex: ['applicant', 'firstName'],
                      title: 'Имя',
                    },
                    {
                      dataIndex: ['applicant', 'age'],
                      title: 'Возраст',
                    },
                    {
                      dataIndex: ['applicant', 'lastJobTitle'],
                      title: 'Должность из резюме',
                    },

                    {
                      dataIndex: ['applicant', 'applicantSource', 'name'],
                      title: 'Источник',
                    },
                    {
                      dataIndex: ['applicant', 'phoneNumber'],
                      title: 'Телефон',
                    },
                    {
                      dataIndex: 'comment',
                      title: 'Комментарий',
                    },
                  ]}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: (event, r) => {
                        this.props.history.push(`applicant-edit/${record.applicant.id}`);
                        console.log(event, r);
                      }, // double click row
                    };
                  }}
                />
              </>
            }
          />
          <Route path="/create" element={<h1>hello</h1>} />
          <Route path={'/test'} element={<h1>hello</h1>} />
          <Route path={'/applicant-edit/:id'} element={<ApplicantData />} />

          <Route path={'/edit/:id'} element={<h1>hello</h1>} />
        </Routes>
      </>
    );
  }
}
export default withRouter(ApplicantTable);
