import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FolderViewOutlined,
  SettingOutlined,
  SolutionOutlined,
  ScheduleOutlined
} from '@ant-design/icons';
import { Button, Popconfirm, Space, Tooltip } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from '../WithRouter';


class ActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Space>
        {this.props.registerForms && (
          <Tooltip title="Просмотр записи">
            <Button
              type="primary"
              shape="circle"
              size="small"
              ghost
              onClick={() => this.props.history.push(`myTasks/RegisterForms`)}>
              <FolderViewOutlined />
            </Button>
          </Tooltip>
        )}
        {!this.props?.hidden?.includes('show') && (
          <Tooltip title="Согласование HRBP">
            <Link to={this.props.match.params.pathname + `/view/${this.props.id}`}>
              <Button
                onClick={() => {
                  this.props.getItem && this.props.getItem(this.props.id);
                }}
                style={{ padding: 0 }}
                type="primary"
                size="small"
                shape="circle"
                ghost>
                <ScheduleOutlined />
              </Button>
            </Link>
          </Tooltip>
        )}
      

        {!this.props?.hidden?.includes('edit') && (
          <Tooltip title="Редактирование записи">
            <Link to={this.props.match.params.pathname + `/edit/${this.props.id}`}>
              <Button
                onClick={() => {
                  this.props.getItem && this.props.getItem(this.props.id);
                }}
                className={'actionGreenButton'}
                type="primary"
                size="small"
                shape="circle"
                ghost>
                <EditOutlined />
              </Button>
            </Link>
          </Tooltip>
        )}

        {!this.props?.hidden?.includes('delete') && (
          // <Tooltip title="Удаление записи">
          <Popconfirm
            title="Удаление записи"
            onConfirm={() => {
              this.props.deleteItem(this.props.id);
            }}
            okText="Да"
            cancelText="Нет">
            <Button className={'actionRedButton'} type="primary" size="small" shape="circle" ghost>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
          // </Tooltip>
        )}
      </Space>
    );
  }
}

export default withRouter(ActionButtons);
