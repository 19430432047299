

import React from 'react';

import MyChart from '../components/Orgchart';
import ApiDiContainer from '../apiService/apiDiContainer';
import { message } from 'antd';

class PageOrgchart extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      data:[]
    };
  }

  componentDidMount(){
    ApiDiContainer.ProxyApiAction.getOrgchart().then((res)=>{
      this.setState({data:res})
    })
  }

  getChilden(id){
return ApiDiContainer.ProxyApiAction.getOrgchartChildren(id).then((res)=>res).catch((er)=>message.error('Отсутсвуют подчиненные'))
  }

  render(){
    return(

      <MyChart
      getChilden={this.getChilden}
    data={this.state.data}
    />
    )
  }
}
export default (PageOrgchart)
