import { Card, Progress, Typography } from 'antd';
import logotype from '../Logo.svg'
import Title from 'antd/es/typography/Title';
import elips from '../Ellipse.png'
import { useState } from 'react';
const LoaderSovkom = () => {

  const [percent, setPercent] = useState(0);
  const increase = () => {
    setPercent((prevPercent) => {
      const newPercent = prevPercent + 10;
      if (newPercent > 90) {
        return 100;
      }
      return newPercent;
    });
  };
  setTimeout(()=>{
    if(percent>95){
      setPercent(0)
    }
    else{
      setPercent(percent+5)
    }
   
  },100)
  return (
    <>
        {' '}
        <div className='loaderWrapper' style={{width:'100%'}}>
          {' '}
          <div
            className="stylesLogo"
            style={{  position: 'absolute',  }}>
            <img src={logotype} alt="logo" width={'60px'} />
            <Title style={{ whiteSpace: 'nowrap' }}  level={4} className="titleLogo loaderText">
              Загрузка...
            </Title>

            <Progress style={{width:'200px'}}  showInfo={false} strokeColor="#003791" percent={percent} />
          </div>

      
        </div>
       
      </>
  );
};
export default LoaderSovkom;
