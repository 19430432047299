import { notification } from 'antd';
import ApiConnector from './restClient';
const camunda = 'unnd';
class ApiAction {
  // универсальные методы
  static getAll(path, updateProgress) {
    return ApiConnector.get(`/${path}`, updateProgress);
  }
  static getItem(path, id) {
    return ApiConnector.get(`/${path}/${id}`);
  }
  static deleteItem(path, id) {
    return ApiConnector.delete(`/${path}/${id}`);
  }
  static patchItem(path, data) {
    return ApiConnector.patch(`/${path}`, data);
  }
  static postItem(path, data) {
    return ApiConnector.post(`/${path}`, data);
  }
  static postBlobItem(path, data) {
    return ApiConnector.postBlob(`/${path}`, data);
  }
  static getBlobItem(path, data) {
    return ApiConnector.getBlob(`/${path}`, data);
  }


  
  static getRecruters(id) {
    return ApiConnector.get(`/keycloak/recruters`);
  }

  static postAllApplicants(data) {
    return ApiConnector.post(`/applicant/all`,data);
  }

  static getCandidate(id) {
    return ApiConnector.get(`/applicant/${id}`);
  }
  static postApplicant(data) {
    return ApiConnector.post(`/applicant`,data);
  }
  static deleteApplicant(id) {
    return ApiConnector.deleteAxios(`/applicant/${id}`,);
  }

  //VACANCY
  static getAllVacancy(filters) {
    return ApiConnector.post(`/vacancy/all`,filters);
  }
  static getVacancy(id) {
    return ApiConnector.get(`/vacancy/${id}`);
  }

  static addVacancy(data) {
    return ApiConnector.post(`/vacancy`,data);
  }
  static updateStatusVacancy(data) {
    return ApiConnector.post(`/vacancy/vacancy-status-update`,data);
  }



  
  //экспорты
 


  static exportVacancy(arr) {
    return ApiConnector.postBlob(`/api/export-vacancies`,arr,'https://hr.report.flareon.ru');
  }
  static exportApplicant(arr) {
    return ApiConnector.postBlob(`/api/export-applicant`,arr,'https://hr.report.flareon.ru');
  }
  
  //Applicant
  static getApplicantByStageAndVacancy(stageId,vacancyId) {
    return ApiConnector.get(`/applicant/applicants-by-stage-vacancy?stageId=${stageId}&vacancyId=${vacancyId}`);
  }

  static getCurrentApplicant(applicantId,vacancyId) {
    return ApiConnector.get(`/applicant/applicant-by-stage-vacancy?applicantId=${applicantId}&vacancyId=${vacancyId}`);
  }
  static getApplicant(id) {
    return ApiConnector.get(`/applicant/${id}`);
  }
  static getAllApplicant() {
    return ApiConnector.post(`/applicant/all`,{});
  }
  static addRelationVacancyApplicant(data) {
    return ApiConnector.post(`/vacancy-applicant`,data);
  }
  static changeStatusApplicant(data) {
    return ApiConnector.post(`/applicant/applicant-status-update`,data);
  }

  static addApplicant(data) {
    return ApiConnector.post(`/applicant`,data);
  }

  
  //chart

  static getOrgchart() {
    return ApiConnector.get(`/keycloak/orgs`);
  }
  static getOrgchartChildren(id) {
    return ApiConnector.get(`/keycloak/members-by-group?groupId=${id}`);
  }
  static getOrgchartByGroup(id) {
    return ApiConnector.get(`/keycloak/orgs-by-group?groupId=${id}`);
  }
  //user


  static getCurrentUser() {
    return ApiConnector.get(`/keycloak/current-user`);
  }
  static getUsers() {
    return ApiConnector.get(`/keycloak/users`);
  }
  // role

  static getRole(id) {
    return ApiConnector.get(`/keycloak/role/${id}`);
  }
  static postAllRoles(data) {
    return ApiConnector.post(`/keycloak/role`,data);
  }
  static postRole(data) {
    return ApiConnector.post(`/stage`,data);
  }
  static deleteRole(id) {
    return ApiConnector.deleteAxios(`/stage/${id}`,);
  }

  // stage-entity
  static getStage(id) {
    return ApiConnector.get(`stage/${id}`);
  }
  static postAllStages(data) {
    return ApiConnector.post(`/stage/all`,data);
  }
  static postStage(data) {
    return ApiConnector.post(`/stage`,data);
  }
  static deleteStage(id) {
    return ApiConnector.deleteAxios(`/stage/${id}`,);
  }
  
  static getAllCalendar(data) {
    return ApiConnector.post(`/calendar/all`,data);
  }
  static createCalendarEvent(data) {
    return ApiConnector.post(`/calendar/calendar-create`,data);
  }


  //колокольчики и чаты
 
  
  static getNumOfNotification() {
    return ApiConnector.get(`/notifications/count`, 'https://hr.api.notification.flareon.ru' );
  }

  static getChats() {
    return ApiConnector.get(`/notifications/chat`, 'https://hr.api.notification.flareon.ru' );
  }
  static getChatById(id) {
    return ApiConnector.get(`/notifications/chat-by-applicant?vacancyApplicantId=${id}`, 'https://hr.api.notification.flareon.ru' );
  }

  static sendMessage(id) {
    return ApiConnector.post(`/email/send`,id, 'https://hr.api.notification.flareon.ru' );
  }
  
}
export default ApiAction;
