import { Button, Card, Divider, Space, message } from 'antd';
import React from 'react';
import { withRouter } from '../WithRouter';
import ApiDiContainer from '../apiService/apiDiContainer';

import vacancyStore from '../state/VacancyStore';
import VacancyData from './VacancyData';
import AddRelationVacancyApplicant from './AddRelationVacancyApplicant';
import moment from 'moment';

class AddRelationVacancy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantUser: {},
    };
  }
  componentDidMount() {
    this.getCurrentApplicant();
  }

  getCurrentApplicant() {
    ApiDiContainer.ProxyApiAction.getVacancy(this.props.params.id).then((res) => {
      this.setState({ ...res });

      vacancyStore.newVariables(res);
    });
  }

  render() {
    return (
      <Card style={{ borderRadius: '20px' }}>
      

        <VacancyData />


        <AddRelationVacancyApplicant
        onLoad={
          ()=>{
            ApiDiContainer.ProxyApiAction.getApplicantByStageAndVacancy('',).then((res)=>{

              vacancyStore.updateVariable('selectedApplicant',res?.map((el)=>el.id))
            })
          }
        }
       />
        <Divider/>


<div style={{display:'flex',justifyContent: "flex-end"}}>

<Space>


  <Button
  type='primary'

    onClick={() => {
      vacancyStore.getVariable('selectedApplicant').map((el)=>{
        ApiDiContainer.ProxyApiAction.addRelationVacancyApplicant(
          {
            applicant:{
              id:el
            },
            vacancy:{
              id:vacancyStore.getVariable('id')
            },
            stage:{
              id:10
            },
            timeEnteredStage:moment()
           },
         ).then(()=>{
           message.success('Связи кандидатов вакансией установлены')
           this.props.history.push(`/application`);
           this.props.getAllVacancy({})
         })
      }
      
      
      )
    
    }}>
    Назначить
  </Button>
</Space>

</div>
      </Card>
    );
  }
}
export default withRouter(AddRelationVacancy);
