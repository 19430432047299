import React from 'react';

import { PlusSquareOutlined,SettingOutlined,UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Badge, Breadcrumb, Button, Card, Col, List, Radio, Row, Space, Spin, Table, Tag, TreeSelect, Typography, Upload } from 'antd';

import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../WithRouter';
import ActionButtons from '../components/ActionButtons';
import Title from 'antd/es/typography/Title';
import moment from 'moment/moment';
import ApplicantTable from '../components/ApplicantTable';
import ApiDiContainer from '../apiService/apiDiContainer';

class ChatPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentChat:[]
    };
  }
componentDidMount(){
  this.getAllData();
}
  getAllData() {
    ApiDiContainer.ProxyApiAction.getChats().then((res) => {

      this.setState({ data: res });
    });
  }

  getChatById(id){
   return ApiDiContainer.ProxyApiAction.getChatById(id).then((res)=>{
      this.setState({currentChat:res})
    })
  }
  render() {
    return (
      <Card style={{ margin: 20,height:'inherit' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Title style={{ margin: 0 }}>Страница чатов</Title>
         
        </div>

   <Row gutter={16}>

    <Col span={8}>
   <Card>

   <List
    itemLayout="horizontal"
    dataSource={this.state.data}
    renderItem={(item, index) => (

      <List.Item
      onClick={(e)=>{

this.setState({spinning:true})
this.getChatById(item?.vacancyApplicantId).then((res)=>{
  this.setState({spinning:false})
})
      }}
      >
        
        <List.Item.Meta
          avatar={  <Badge count={item.countNew}>
            {item.icon? <Avatar src={item.icon } /> :       <Avatar icon={<UserOutlined />} /> }

          </Badge>}
          title={item.vacancyTitle+"-"+item.fio}
          description={<Typography.Text
            ellipsis={{
              rows: 6,
           
            }}
          
          >{item.lastMessagePreview}</Typography.Text>}

        
        />

<Tag >{item?.lastMessageDate && moment(item?.lastMessageDate).format('LLL')}</Tag>


      </List.Item>
    
    
    )}
  />
   </Card>
    </Col>
    <Col span={16}>
    <Card>
{this.state.currentChat.length!==0 ?

<Spin spinning={this.state.spinning}>

<List
  itemLayout="horizontal"
  pagination
  dataSource={this.state.currentChat}
  renderItem={(item, index) => (
    <List.Item

    actions={item?.vacancyApplicantEntity&&[<a target='_blank' href={`/application/vacancy/${item.vacancyApplicantEntity.vacancy.id}/applicant-edit/${item.vacancyApplicantEntity.applicant.id}`} rel="noreferrer" >Открыть</a>]}
    
    >
      <List.Item.Meta
        avatar={<>
      {item?.type.startsWith('Уведомление от кандидата') ?
        <Avatar src={`${item?.vacancyApplicantEntity?.applicant?.photoUrl}`} /> :   <Avatar icon={<UserOutlined />} />
      }
      </>}
        title={ item?.type.startsWith('Уведомление от кандидата') ? item?.vacancyApplicantEntity?.applicant?.lastName +' ' + item?.vacancyApplicantEntity?.applicant?.firstName +' ' + item?.vacancyApplicantEntity?.applicant?.middleName  :'Система'}
        description={item.message}
      />

<Tag >{item?.dtCreate && moment(item?.dtCreate).format('LLL')}</Tag>
    </List.Item>
  )}
/>
</Spin>
:
<Alert
message="Чат не задан"
description="Выберите пользователя в левой части экрана для отображения уведомлений."
type="info"
/>
}
</Card>
    </Col>
   </Row>
      </Card>
    );
  }
}
export default withRouter(ChatPage);
