import React from 'react';

import { PlusSquareOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Radio, Space, Table, Tag, TreeSelect, Upload } from 'antd';

import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../WithRouter';
import ActionButtons from '../components/ActionButtons';
import Title from 'antd/es/typography/Title';
import moment from 'moment/moment';
import ApplicantTable from '../components/ApplicantTable';
import ApiDiContainer from '../apiService/apiDiContainer';

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
componentDidMount(){
  this.getAllData();
}
  getAllData() {
    ApiDiContainer.ProxyApiAction.getUsers().then((res) => {

      this.setState({ data: res });
    });
  }
  render() {
    return (
      <Card style={{ margin: 20 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Title style={{ margin: 0 }}>Пользователи</Title>
          <Space>
            <Button
              onClick={() => {
                this.props.history.push(`create`);
              }}
              type="primary">
              Добавить
            </Button>
          </Space>
        </div>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <Table
                  dataSource={this.state.data}
                  columns={[
                    {
                      dataIndex: 'firstName',
                      title: 'Фамилия',
                    },
                    {
                      dataIndex: 'lastName',
                      title: 'Имя',
                    },
                    {
                      dataIndex: 'login',
                      title: 'Логин',
                    },
                  
                    {
                      dataIndex: 'created',
                      title: 'Дата создания',
                    },
                    {
                      dataIndex: 'roles',
                      title: 'Роли',
                      render:(arr)=>arr?.map((el)=><Tag>{el.name}</Tag>)
                    },
                    {
                      dataIndex: 'id',
                      title: 'Действия',
                      width: 100,
                      render: (record, data) => {
                        return <ActionButtons id={data.id} />;
                      },
                    },
                  ]}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: (event, r) => {
                        this.props.history.push(`test`, {
                          department: 'Отдел тестирования',
                        });
                        console.log(event, r);
                      }, // double click row
                    };
                  }}
                />
              </>
            }
          />
          <Route path="/create" element={<h1>hello</h1>} />
      
          <Route path={'/view/:id'} element={<h1>hello</h1>} />

          <Route path={'/edit/:id'} element={<h1>hello</h1>} />
        </Routes>
      </Card>
    );
  }
}
export default withRouter(UsersPage);
