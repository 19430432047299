import { Button, Col, Input, InputNumber, Modal, Radio, Row, Select, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import currentUserStore from '../state/CurrentUserStore';
import { toJS } from 'mobx';
import vacancyStore from '../state/VacancyStore';
import { observer } from 'mobx-react';

class VacancyData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedRowKeys: [],
      treeData: [],
      selectedArray: [],
      lastUpdated: '2023-05-26T16:51:47.239Z',
      
      
      
      
      isArchive: true,
      createdBy: 'string',
      recruiter: 'string',
      status: {
        id: 0,
        title: 'string',
        description: 'string',
      },
      numToHire: 0,
      inWorkSince: '2023-05-26T16:51:47.239Z',
      department: 'string',
     
    
      
      title: '',
      salaryMin: 0,
      salaryMax: 0,
      workExperienceMax: 0,
      workExperienceMin: 0,
      description: '',
      applicantRequirements: '',
      workSchedule: {
        name: '',
      },
    };
  }


  render() {
    console.log(this.state)
    console.log(toJS(vacancyStore))
    return (
      <>
        <Row>
          <Col span={24}>
            <Typography.Text>Название вакансии</Typography.Text>
            <Input value={vacancyStore.getVariable('title')} onChange={(e)=>vacancyStore.updateVariable('title',e.target.value)}/>
          </Col>
          <Col span={24}>
            <Typography.Text>Подразделение</Typography.Text>
            <Select value={vacancyStore.getVariable("department")} disabled  style={{ width: '100%' }}></Select>
          </Col>
          <Col span={24}>
            <Typography.Text>Заработная плата</Typography.Text>
<br/>
            <InputNumber value={vacancyStore.getVariable('salaryMin')} onChange={(e)=>vacancyStore.updateVariable('salaryMin',e)} addonBefore={'oт'} addonAfter={'руб.'} />
            <InputNumber value={vacancyStore.getVariable('salaryMax')} onChange={(e)=>vacancyStore.updateVariable('salaryMax',e)} addonBefore={'до'} addonAfter={'руб.'} />
          </Col>

          <Col span={24}>
            <Typography.Text>Требуемый опыт работы</Typography.Text>
            <Row gutter={8}>
              <Col span={12}>
                {' '}
                <InputNumber value={vacancyStore.getVariable('workExperienceMin')} onChange={(e)=>vacancyStore.updateVariable('workExperienceMin',e)} placeholder="от" style={{ width: '100%' }} />
              </Col>
              <Col span={12}>
                <InputNumber value={vacancyStore.getVariable('workExperienceMax')} onChange={(e)=>vacancyStore.updateVariable('workExperienceMax',e)}  placeholder="до" style={{ width: '100%' }} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Typography.Text>Описание вакансии</Typography.Text>
            <TextArea style={{ width: '100%' }}
             value={vacancyStore.getVariable('description')} onChange={(e)=>vacancyStore.updateVariable('description',e.target.value)} 
            />
          </Col>

          <Col span={24}>
            <Typography.Text>Требования к кандидатам</Typography.Text>
            <TextArea style={{ width: '100%' }}
             value={vacancyStore.getVariable('applicantRequirements')} onChange={(e)=>vacancyStore.updateVariable('applicantRequirements',e.target.value)} 
            />
          </Col>
          <Col span={24}>
            <Typography.Text>Обоснование для открытия вакансии</Typography.Text>
            <TextArea style={{ width: '100%' }} />
          </Col>
          <Col span={24}>
            <Typography.Text>Создатель вакансии</Typography.Text>
            <Input disabled value={vacancyStore.getVariable('createdBy') } style={{ width: '100%' }} />
          </Col>
          <Col span={24}>
            <Typography.Text>Необходимо сотрудников</Typography.Text>
            <InputNumber value={vacancyStore.getVariable('numToHire')} onChange={(e)=>vacancyStore.updateVariable('numToHire',e)}  style={{ width: '100%' }} />
          </Col>
          <Col span={24}>
            <Typography.Text>Занятость</Typography.Text><br/>
            <Radio.Group value={vacancyStore.getVariable('workSchedule')?.name} onChange={(e)=>vacancyStore.updateVariable('workSchedule',{
              name:e.target.value
            })}>
              <Radio value={'Полная занятость'}>Полная занятость</Radio>
              <Radio value={'Волонтерство'}>Волонтерство</Radio>
              <Radio value={'Частичная занятость'}>Частичная занятость</Radio>
              <Radio value={'Стажирвка'}>Стажирвка</Radio>
              <Radio value={'Проектная работа'}>Проектная работа</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </>
    );
  }
}
export default observer(VacancyData);
