import { Calendar, Popover, Typography } from 'antd';
import moment from 'moment';
import { Component } from 'react';


const {Text}=Typography
class TaskCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOfTasks: Object.values(this.props),
      content: false,
      id: null,
      arrColor: [
        '#FC5055',
        '#003791',
        '#AC0106',
       
      ],
    };
  }
  monthCellRender = (value) => {
    let listData = this.getListDataYear(value);

    return (
      <>
        {listData.map((item) => (
          <>
            <Popover
              onMouseEnter={() => console.log(listData)}
              content={() => (
                <div>
                  <Text strong>{item.musor.name}</Text>
                  <br />
                  <Text>
                    <Text strong>Дата начала: </Text>
                    {moment(item.musor.created).format('ll')}
                  </Text>
                  <br />
                  <Text>
                    <Text strong>Дата конца: </Text>
                    {moment(item.dueDate.value).format('ll')}
                  </Text>
                </div>
              )}>
              <span
                key={item.id}
                style={{ background: this.state.arrColor[0] }}
                className={'calendarTaskYear'}></span>
            </Popover>
          </>
        ))}
      </>
    );
  };

  dateCellRender = (value) => {
    let listData = this.getListData(value);
    console.log(`listData`,listData)
    return (
      <>
        {listData.map((item) => {
          if(moment(item.dtPlanned).format('YYYY/MM/DD').includes(value.format('YYYY/MM/DD'))){
          return   <Popover
          content={() => (
            <div>
              <Text strong> {item.title}</Text>
              <br />
              <Text strong> {item.description}</Text>
              <br />
              <Text>
                <Text strong>Дата начала: </Text>
                {moment(item.dtPlanned).format('ll')}
              </Text>
              <br/>
              <Text>
                <Text strong><a href={item.meetingUrl} target='_blank' rel="noreferrer">Ссылка на собес </a></Text>
              
              </Text>

             
            </div>
          )}>
          <div
            key={item.id}
           
          
            style={{ background: this.state.arrColor[1],color:'white',textAlign:'center' }}
            className={'calendarTask'}>   {item.title}</div>
        </Popover>
          }
        })}
        


        
      
      </>
    );
  };

  getListData = (value) => {
    const listData = this.props.data.map((el, index) => {
      console.log(el)
      return {
        ...el,
        during: [el.dtPlanned],
        content: el.description,
        id: index,
      };
    });

 
    return listData
    
  };
    render(){

      return <Calendar  dateCellRender={this.dateCellRender}  monthCellRender={this.monthCellRender} mode="month" />;
    }



}

export default TaskCalendar;
