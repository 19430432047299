import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './Keycloak';
import LoaderSovkom from './components/LoaderSovkom';
import moment from 'moment';
const root = ReactDOM.createRoot(document.getElementById('root'));


moment.updateLocale('ru', {
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  weekdays: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
  monthsShort: [
    'Янв',
    'Февр',
    'Март',
    'Апр',
    'Май',
    'Июнь',
    'Июль',
    'Авг',
    'Сен',
    'Окт',
    'Нояб',
    'Дек',
  ],
  weekdaysMin: ['Пн', 'Вт', 'Cр', 'Чт', 'Пт', 'Суб', 'Вс'],
  weekdaysShort: ['Пон', 'Вт', 'Cр', 'Чт', 'Пт', 'Субб', 'Вскр'],
});
root.render(


  <ReactKeycloakProvider
  authClient={keycloak}
  initOptions={{ onLoad: 'login-required', pkceMethod: 'S256' }}
  LoadingComponent={<LoaderSovkom/>}
  onEvent={(event, error) => console.log(`onEvent`, event, error)}
  onTokens={(token) => sessionStorage.setItem('token', token.idToken)}
  // onEvent={(event, error) => sessionStorage.setItem('token', keycloak.idToken)}
>
<BrowserRouter basename="/">
    <React.StrictMode>
      <ConfigProvider
        theme={{
          // algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: '#003791',
          },

          // components: {
          //   Table: {
          //     colorBgContainer: '#70b6d2',

          //   },
          // },
        }}>
        <App />
      </ConfigProvider>
    </React.StrictMode>
  
  </BrowserRouter>
</ReactKeycloakProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
