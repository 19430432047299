import { BellOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Col,
  DatePicker,
  Input,
  List,
  Modal,
  Popover,
  Row,
  Tabs,
  Typography,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { toJS } from 'mobx';
import moment from 'moment';
import React from 'react';
import vacancyApplicantStore from '../state/VacancyApplicantStore';
import { observer } from 'mobx-react';
import currentUserStore from '../state/CurrentUserStore';
import dayjs from 'dayjs';
import ApiDiContainer from '../apiService/apiDiContainer';
import { withRouter } from '../WithRouter';
const { Text } = Typography;

const { TabPane } = Tabs;

class ModalShowInterview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countNitification: '',
      newNotification: [],
      listLoadingStatus: false,
      isModalOpen: true,
    };
  }

  componentDidMount = () => {};

  render() {
    console.log(toJS(vacancyApplicantStore));

    return (
      <Modal
        title="Планирование оффера"
        open={this.props.showPlanInterview}
        onOk={() => {
          this.props.isShow(false);
          ApiDiContainer.ProxyApiAction.changeStatusApplicant({
            applicant: {
              id: vacancyApplicantStore.getVariable('applicant')?.id,
            },
            stage: {
              id: 18,
            },
            vacancy: {
              id: vacancyApplicantStore.getVariable('vacancy')?.id,
            },
            comment: vacancyApplicantStore.getVariable('comment'),
          }).then((res) => {
            ApiDiContainer.ProxyApiAction.sendMessage({
              message: vacancyApplicantStore.getVariable('message'),
              keycloakUserId: currentUserStore.getVariable('id'),
              keycloakUserEmail: currentUserStore.getVariable('email'),
              notificationType: 'Оффер',
              date: moment(),
              meetingUrl: '',
             
              applicantEmail:vacancyApplicantStore.getVariable('applicant')?.email,
           
              vacancyApplicantId:vacancyApplicantStore.getVariable('id')
       
           
            }).then(() => {
              this.props.history.back();
              message.success(`Кандидат перешел на статус Выставлен оффер`);
            });
          });
        }}
        onCancel={() => {
          this.props.isShow(false);
        }}>
        <Row>
          <Col span={24}>
            <Text>Кому: </Text>
            <Text strong>{vacancyApplicantStore.getVariable('applicant')?.email}</Text>
          </Col>
          <Col span={24}>
            <Text>В копии</Text> <Text strong>{currentUserStore.getVariable('email')}</Text>
          </Col>
          <Col span={24}>
            <Text>Кандидат:</Text>
            <Text strong>
              {' '}
              {vacancyApplicantStore.getVariable('applicant')?.lastName +
                ' ' +
                vacancyApplicantStore.getVariable('applicant')?.middleName +
                ' ' +
                vacancyApplicantStore.getVariable('applicant')?.firstName}
            </Text>
          </Col>
          <Col span={24}>
            <Text>Вакансия: </Text>
            <Text strong>{vacancyApplicantStore.getVariable('vacancy')?.title}</Text>
          </Col>

          <Col span={24}>
            <Text>Сообщение</Text>

            <Input.TextArea
              value={vacancyApplicantStore.getVariable('message')}
              onChange={(e) => {
                vacancyApplicantStore.updateVariable('message', e.target.value);
              }}></Input.TextArea>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default observer(withRouter(ModalShowInterview));
