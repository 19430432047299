import React from 'react';

import { PlusSquareOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Radio, Space, Table, Tag, TreeSelect, Upload } from 'antd';

import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../WithRouter';
import ActionButtons from '../components/ActionButtons';
import Title from 'antd/es/typography/Title';
import moment from 'moment/moment';
import ApplicantTable from '../components/ApplicantTable';
import ApiDiContainer from '../apiService/apiDiContainer';
import TaskCalendar from '../components/taskCalendar';

class CalendarPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
componentDidMount(){
  this.getAllData();
}
  getAllData() {
    ApiDiContainer.ProxyApiAction.getAllCalendar({}).then((res) => {

      this.setState({ data: res.content
      });
    });
  }
  render() {
    console.log(this.state.data)
    return (
  <TaskCalendar data={this.state.data||[]}/>
    );
  }
}
export default withRouter(CalendarPage);
