import {
  Card,
  Col,
  Divider,
  Image,
  Input,
  Row,
  Space,
  Typography
} from 'antd';
import React from 'react';
import { withRouter } from '../WithRouter';
import ApiDiContainer from '../apiService/apiDiContainer';
import candidateStore from '../state/CandidateStore';

class CandidateData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantUser: {},
    };
  }
  componentDidMount() {
    this.getCurrentApplicant();
  }

  getCurrentApplicant() {
    this.props.params.id &&
      ApiDiContainer.ProxyApiAction.getCandidate(this.props.params.id).then((res) => {
        this.setState({ ...res });

        candidateStore.newVariables(res);
      });
  }

  render() {
    return (
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          xxl={12}
          style={{ padding: '24px', background: '#003791BF', borderRadius: '20px' }}>
          <Card>
            <Row type="flex">
              <Col span={8}>
                {!this.props.create ? (
                  <Image
                    style={{ borderRadius: '50%' }}
                    width={200}
                    src={candidateStore.getVariable('photoUrl')}
                  />
                ) : (
                  <Input
                    placeholder="url фотографии"
                    value={candidateStore.getVariable('photoUrl')}
                    onChange={(e) => {
                      candidateStore.updateVariable('photoUrl', e.target.value);
                    }}
                  />
                )}
              </Col>

              <Col>
                {!this.props.create ? (
                  <Typography.Text
                    style={{
                      fontWeight: '500',
                      fontSize: '20px',
                    }}>
                    {candidateStore.getVariable('lastName')}{' '}
                  </Typography.Text>
                ) : (
                  <Input
                    placeholder="Фамилия"
                    value={candidateStore.getVariable('lastName')}
                    onChange={(e) => candidateStore.updateVariable('lastName', e.target.value)}
                  />
                )}
                {!this.props.create ? (
                  <Typography.Text
                    style={{
                      fontWeight: '500',
                      fontSize: '20px',
                    }}>
                    {candidateStore.getVariable('middleName')}{' '}
                  </Typography.Text>
                ) : (
                  <Input
                    placeholder="Отчество"
                    value={candidateStore.getVariable('middleName')}
                    onChange={(e) => candidateStore.updateVariable('middleName', e.target.value)}
                  />
                )}

                {!this.props.create ? (
                  <Typography.Text
                    style={{
                      fontWeight: '500',
                      fontSize: '20px',
                    }}>
                    {candidateStore.getVariable('firstName')}{' '}
                  </Typography.Text>
                ) : (
                  <Input
                    placeholder="Имя"
                    value={candidateStore.getVariable('firstName')}
                    onChange={(e) => candidateStore.updateVariable('firstName', e.target.value)}
                  />
                )}

                {!this.props.create ? (
                  <Typography.Text
                    style={{
                      color: '#003791',
                      fontWeight: '500',
                      fontSize: '20px',
                    }}>
                    {candidateStore.getVariable('age')} лет
                  </Typography.Text>
                ) : (
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Возраст"
                    value={candidateStore.getVariable('age')}
                    onChange={(e) => candidateStore.updateVariable('age', e.target.value)}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          xxl={12}
          style={{ background: '#FC5055C2', padding: '24px', borderRadius: '20px' }}>
          <Card>
            <div>
              <Typography.Title style={{ textAlign: 'center', marginTop: 0 }}>
                Основное
              </Typography.Title>
            </div>

            <Space direction="vertical">
              {!this.props.create ? (
                <Typography.Text
                  style={{
                    fontWeight: '400',
                    fontSize: '17px',
                  }}>
                  <b>Образование</b>: {candidateStore.getVariable('education')}
                </Typography.Text>
              ) : (
                <Input
                  style={{ width: '100%' }}
                  placeholder="Образование"
                  value={candidateStore.getVariable('education')}
                  onChange={(e) => candidateStore.updateVariable('education', e.target.value)}
                />
              )}

              {!this.props.create ? (
                <Typography.Text
                  style={{
                    fontWeight: '400',
                    fontSize: '17px',
                  }}>
                  <b>Номер телефона</b>: {candidateStore.getVariable('phoneNumber')}
                </Typography.Text>
              ) : (
                <Input
                  style={{ width: '100%' }}
                  placeholder="Номер телефона"
                  value={candidateStore.getVariable('phoneNumber')}
                  onChange={(e) => candidateStore.updateVariable('phoneNumber', e.target.value)}
                />
              )}

{!this.props.create ? (
                <Typography.Text
                  style={{
                    fontWeight: '400',
                    fontSize: '17px',
                  }}>
                  <b>Почта</b>: {candidateStore.getVariable('email')}
                </Typography.Text>
              ) : (
                <Input
                  style={{ width: '100%' }}
                  placeholder="Почта"
                  value={candidateStore.getVariable('email')}
                  onChange={(e) => candidateStore.updateVariable('email', e.target.value)}
                />
              )}

{!this.props.create ?
              <Typography.Text
                style={{
                  fontWeight: '400',
                  fontSize: '17px',
                }}>
                <b>Опыт работы:</b> {candidateStore.getVariable('totalWorkExperience')}
              </Typography.Text> :
                <Input
                  style={{ width: '100%' }}
                  placeholder="Опыт работы"
                  value={candidateStore.getVariable('totalWorkExperience')}
                  onChange={(e) => candidateStore.updateVariable('totalWorkExperience', e.target.value)}
                />
              }

{!this.props.create ?
              <Typography.Text
                style={{
                  fontWeight: '400',
                  fontSize: '17px',
                }}>
                <b>Источник: </b>
                {candidateStore.getVariable('applicantSource')?.name}
              </Typography.Text> :
                <Input
                disabled
                  style={{ width: '100%' }}
                  placeholder="Источник"
                  value={candidateStore.getVariable('applicantSource')?.name}
                  onChange={(e) => candidateStore.updateVariable('applicantSource', {name:e.target.value})}
                />
              }
            </Space>

            <Divider
              orientation="center"
              style={{
                fontSize: '20px',
              }}>
              Информация с прошлой работы
            </Divider>
            <Space direction="vertical">

            {!this.props.create ?
              <Typography.Text
                style={{
                  fontWeight: '400',
                  fontSize: '17px',
                }}>
                <b>Место работы: </b>
                {candidateStore.getVariable('lastJobCompanyName')}
              </Typography.Text> : <Input
                
                  style={{ width: '100%' }}
                  placeholder="Место работы"
                  value={candidateStore.getVariable('lastJobCompanyName')}
                  onChange={(e) => candidateStore.updateVariable('lastJobCompanyName', e.target.value)}
                />
              }

{!this.props.create ?
              <Typography.Text
                style={{
                  fontWeight: '400',
                  fontSize: '17px',
                }}>
                <b>Должность: </b>
                {candidateStore.getVariable('lastJobTitle')}
              </Typography.Text> : <Input
                
                style={{ width: '100%' }}
                placeholder="Должность"
                value={candidateStore.getVariable('lastJobCompanyName')}
                onChange={(e) => candidateStore.updateVariable('lastJobCompanyName', e.target.value)}
              />
            }
          {!this.props.create ?    <Typography.Text
                style={{
                  fontWeight: '400',
                  fontSize: '17px',
                }}>
                <b>Обязанности: </b>
                {candidateStore.getVariable('lastJobDuties')}
              </Typography.Text>
              : <Input
                
                  style={{ width: '100%' }}
                  placeholder="Обязанности"
                  value={candidateStore.getVariable('lastJobDuties')}
                  onChange={(e) => candidateStore.updateVariable('lastJobDuties', e.target.value)}
                />
              }
            </Space>
          </Card>
        </Col>
      </Row>
    );
  }
}
export default withRouter(CandidateData);
