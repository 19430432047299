import { useLocation, useNavigate, useParams } from 'react-router';

export const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const match = { params: useLocation() };
  const location = useLocation();
  const navigate = useNavigate();
  const history = {
    back: () => navigate(-1),
    goBack: () => navigate(-1),
    location,
    push: (url, state) => navigate(url, { state }),
    replace: (url, state) =>
      navigate(url, {
        replace: true,
        state,
      }),
  };
  // console.log(`params`, params);
  // console.log(`match`, match);
  // console.log(`location`, location);
  // console.log(`navigate`, navigate);
  // console.log(`history`, history);
  return (
    <WrappedComponent
      {...props}
      history={history}
      params={params}
      match={match}
      navigate={navigate}
      // etc...
    />
  );
};
