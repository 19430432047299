import { UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  List,
  Row,
  Space,
  Typography,
  message,
} from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { withRouter } from '../WithRouter';
import ApiDiContainer from '../apiService/apiDiContainer';
import currentUserStore from '../state/CurrentUserStore';
import vacancyApplicantStore from '../state/VacancyApplicantStore';
import ModalPlanInterview from './ModalPlanInterview';
import ModalShowInterview from './ModalShowInterview';

class ApplicantData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantUser: {},
    };
  }
  componentDidMount() {
    this.getCurrentApplicant();
  }

  getCurrentApplicant() {
    ApiDiContainer.ProxyApiAction.getCurrentApplicant(
      this.props.params.id,
      this.props.match.params.pathname.split('/')[3],
    ).then((res) => {
      this.setState({ ...res });
      vacancyApplicantStore.newVariables(res);
      vacancyApplicantStore.updateVariable('comment', '');
      this.getNewStatusForStage(res.stage.name);
    });
  }

  getNewStatusForStage(status) {
    switch (status) {
      case 'Новый': // if (x === 'value1')
        console.log('Новый');
        this.setState({
          nextStatus: {
            id: 15,
            name: 'Интервью',
          },
        });
        break;
      // eslint-disable-next-line no-fallthrough
      case 'Интервью':
        this.setState({
          nextStatus: {
            id: 16,
            name: 'К заказчику',
          },
        });
        break;
      case 'К заказчику':
        this.setState({
          nextStatus: {
            id: 17,
            name: 'Согласовано заказчиком',
          },
        });
        break;
      case 'Согласовано заказчиком':
        this.setState({
          nextStatus: {
            id: 18,
            name: 'Выставлен оффер',
          },
        });
        break;
      case 'Выставлен оффер':
        this.setState({
          nextStatus: {
            id: 19,
            name: 'Вышел на работу',
          },
        });
        break;

      default:
        break;
    }
  }
  render() {
    console.log(`te`, currentUserStore.getVariable('lastname'));
    console.log('propse', this.props.match.params.pathname.split('/')[3]);
    return (
      <Row gutter={[40]}>
        <ModalPlanInterview
          showPlanInterview={this.state.showPlanInterview}
          isShow={(bool) => {
            this.setState({ showPlanInterview: bool });
          }}
        />
        <ModalShowInterview
          showPlanInterview={this.state.showOffer}
          isShow={(bool) => {
            this.setState({ showOffer: bool });
          }}
        />

        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <Row gutter={8}>
            <Col span={24}>
              <Card style={{ borderRadius: '20px' }}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={10}>
                    <Image
                      style={{ borderRadius: '50%' }}
                      width={200}
                      src={this.state?.applicant?.photoUrl}
                    />
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={14}>
                    <Space direction="vertical" wrap>
                      <div>
                        <Typography.Text style={{ fontWeight: '500', fontSize: '24px' }}>
                          {this.state.applicant?.lastName}{' '}
                        </Typography.Text>

                        <Typography.Text style={{ fontWeight: '500', fontSize: '24px' }}>
                          {this.state.applicant?.middleName}{' '}
                        </Typography.Text>
                        <Typography.Text style={{ fontWeight: '500', fontSize: '24px' }}>
                          {this.state.applicant?.firstName}{' '}
                        </Typography.Text>
                      </div>
                      <Typography.Text
                        style={{ color: '#003791', fontWeight: '500', fontSize: '20px' }}>
                        {this.state.applicant?.age} лет
                      </Typography.Text>

                      <Typography.Text strong style={{ fontSize: '20px' }}>
                        Статус:{' '}
                        <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                          {this.state.stage?.name}
                        </Typography.Text>
                      </Typography.Text>

                      <Space>
                        {/* <Button
                          type="primary"
                          onClick={() => {
                            ApiDiContainer.ProxyApiAction.changeStatusApplicant({
                              applicant: {
                                id: this.state?.applicant?.id,
                              },
                              stage: this.state?.nextStatus,
                              vacancy: {
                                id: this.state?.vacancy?.id,
                              },
                            }).then(() => {
                              this.props.history.back();
                              message.success(
                                `Кандидат перешел на статус ${this.state?.nextStatus?.name}`,
                              );
                            });
                          }}>
                          Сменить на статус "{this.state?.nextStatus?.name}"
                        </Button> */}

                        {this.state?.vacancy?.recruiter?.includes(
                          currentUserStore.getVariable('lastName'),
                        ) &&
                          this.state?.stage?.id == 10 && (
                            <Button
                              type="primary"
                              size="large"
                              onClick={() => this.setState({ showPlanInterview: true })}>
                              Запланировать интервью
                            </Button>
                          )}

                        {this.state?.vacancy?.recruiter?.includes(
                          currentUserStore.getVariable('lastName'),
                        ) &&
                          this.state?.stage?.id == 15 && (
                            <Button
                              size="large"
                              onClick={() => {
                                ApiDiContainer.ProxyApiAction.changeStatusApplicant({
                                  applicant: {
                                    id: this.state?.applicant?.id,
                                  },
                                  stage: {
                                    id: 20,
                                    name: 'Отказ',
                                    description: null,
                                  },
                                  vacancy: {
                                    id: this.state?.vacancy?.id,
                                  },
                                  comment: vacancyApplicantStore.getVariable('comment'),
                                }).then(() => {
                                  this.props.history.back();
                                  message.success(`Кандидат перешел на статус "Отказ"`);
                                });
                              }}>
                              Отказать
                            </Button>
                          )}

                        {this.state?.vacancy?.recruiter?.includes(
                          currentUserStore.getVariable('lastName'),
                        ) &&
                          this.state?.stage?.id == 15 && (
                            <Button
                              size="large"
                              type="primary"
                              onClick={() => {
                                ApiDiContainer.ProxyApiAction.changeStatusApplicant({
                                  applicant: {
                                    id: this.state?.applicant?.id,
                                  },
                                  stage: {
                                    id: 16,
                                    name: 'К заказчику',
                                    description: null,
                                  },
                                  vacancy: {
                                    id: this.state?.vacancy?.id,
                                  },
                                  comment: vacancyApplicantStore.getVariable('comment'),
                                }).then(() => {
                                  this.props.history.back();
                                  message.success(`Кандидат перешел на статус "К заказчику"`);
                                });
                              }}>
                              Согласовать
                            </Button>
                          )}

                        {this.state?.vacancy?.createdBy?.includes(
                          currentUserStore.getVariable('lastName'),
                        ) &&
                          this.state?.stage?.id == 16 && (
                            <Button
                              size="large"
                              onClick={() => {
                                ApiDiContainer.ProxyApiAction.changeStatusApplicant({
                                  applicant: {
                                    id: this.state?.applicant?.id,
                                  },
                                  stage: {
                                    id: 20,
                                    name: 'Отказ',
                                    description: null,
                                  },
                                  vacancy: {
                                    id: this.state?.vacancy?.id,
                                  },
                                  comment: vacancyApplicantStore.getVariable('comment'),
                                }).then(() => {
                                  this.props.history.back();
                                  message.success(`Кандидат перешел на статус "Отказ"`);
                                });
                              }}>
                              Отказать
                            </Button>
                          )}

                        {this.state?.vacancy?.createdBy?.includes(
                          currentUserStore.getVariable('lastName'),
                        ) &&
                          this.state?.stage?.id == 16 && (
                            <Button
                              size="large"
                              type="primary"
                              onClick={() => {
                                ApiDiContainer.ProxyApiAction.changeStatusApplicant({
                                  applicant: {
                                    id: this.state?.applicant?.id,
                                  },
                                  stage: {
                                    id: 17,
                                    name: 'Согласовано заказчиком',
                                  },
                                  vacancy: {
                                    id: this.state?.vacancy?.id,
                                  },
                                  comment: vacancyApplicantStore.getVariable('comment'),
                                }).then(() => {
                                  this.props.history.back();
                                  message.success(
                                    `Кандидат перешел на статус 'Согласовано заказчиком' `,
                                  );
                                });
                              }}>
                              Согласовать
                            </Button>
                          )}

                        {this.state?.vacancy?.recruiter?.includes(
                          currentUserStore.getVariable('lastName'),
                        ) &&
                          this.state?.stage?.id == 17 && (
                            <Button
                              size="large"
                              type="primary"
                              onClick={() => {
                                this.setState({ showOffer: true });
                              }}>
                              Выслать оффер
                            </Button>
                          )}

                        {this.state?.vacancy?.recruiter?.includes(
                          currentUserStore.getVariable('lastName'),
                        ) &&
                          this.state?.stage?.id == 18 && (
                            <Button
                              size="large"
                              onClick={() => {
                                ApiDiContainer.ProxyApiAction.changeStatusApplicant({
                                  applicant: {
                                    id: this.state?.applicant?.id,
                                  },
                                  stage: {
                                    id: 20,
                                  },
                                  vacancy: {
                                    id: this.state?.vacancy?.id,
                                  },
                                  comment: vacancyApplicantStore.getVariable('comment'),
                                }).then(() => {
                                  this.props.history.back();
                                  message.success(`Кандидат перешел на статус "Отказ"`);
                                });
                              }}>
                              Кандидат отказал
                            </Button>
                          )}

                        {this.state?.vacancy?.recruiter?.includes(
                          currentUserStore.getVariable('lastName'),
                        ) &&
                          this.state?.stage?.id == 18 && (
                            <Button
                              size="large"
                              type="primary"
                              onClick={() => {
                                ApiDiContainer.ProxyApiAction.changeStatusApplicant({
                                  applicant: {
                                    id: this.state?.applicant?.id,
                                  },
                                  stage: {
                                    id: 19,
                                    name: 'Вышел на работу',
                                  },
                                  vacancy: {
                                    id: this.state?.vacancy?.id,
                                  },
                                  comment: vacancyApplicantStore.getVariable('comment'),
                                }).then(() => {
                                  this.props.history.back();
                                  message.success(`Кандидат перешел на статус "Вышел на работу" `);
                                });
                              }}>
                              Кандидат вышел на работу
                            </Button>
                          )}
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col
              span={24}
              style={{ background: '#FC5055C2', padding: '24px', borderRadius: '20px' }}>
              <Card style={{ padding: 0, marginBottom: '20px', borderRadius: '20px' }}>
                <Typography.Title style={{ marginTop: 0 }}>Основное</Typography.Title>

                <Space direction="vertical">
                  <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                    Образование:{' '}
                    <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                      {this.state.applicant?.education}
                    </Typography.Text>
                  </Typography.Text>

                  <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                    Номер телефона:{' '}
                    <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                      {this.state.applicant?.phoneNumber}
                    </Typography.Text>
                  </Typography.Text>
                  <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                    Опыт работы:{' '}
                    <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                      {this.state.applicant?.totalWorkExperience}
                    </Typography.Text>
                  </Typography.Text>
                  <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                    Источник:
                    <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                      {' '}
                      {this.state.applicant?.applicantSource?.name}
                    </Typography.Text>
                  </Typography.Text>
                </Space>
              </Card>

              <Card style={{ padding: 0, borderRadius: '20px' }}>
                <Typography.Title style={{ marginTop: 0 }}>
                  Информация с прошлой работы
                </Typography.Title>

                <Space direction="vertical">
                  <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                    Место работы:{' '}
                    <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                      {this.state.applicant?.lastJobCompanyName}
                    </Typography.Text>
                  </Typography.Text>
                  <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                    Должность:{' '}
                    <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                      {this.state.applicant?.lastJobTitle}
                    </Typography.Text>
                  </Typography.Text>
                  <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                    Обязанности:{' '}
                    <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                      {this.state.applicant?.lastJobDuties}
                    </Typography.Text>
                  </Typography.Text>
                </Space>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col  xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} style={{ background: '#003791BF', borderRadius: '20px' }}>

        <Row gutter={8}>
            <Col span={24} style={{ borderRadius: '20px' , padding: '24px',}} >
            <Card style={{  marginBottom: '20px',  borderRadius: '20px' }}>
            <Typography.Title style={{ marginTop: 0 }}>
              {' '}
              Рассматривается на вакансию{' '}
            </Typography.Title>

            <Space direction="vertical">
              <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                Департамент:
                <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                  {this.state.vacancy?.department}
                </Typography.Text>
              </Typography.Text>

              <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                Вакансия:{' '}
                <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                  {this.state.vacancy?.title}
                </Typography.Text>
              </Typography.Text>
              <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                Описание:{' '}
                <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                  <div dangerouslySetInnerHTML={{ __html: this.state.vacancy?.description }} />
                </Typography.Text>
              </Typography.Text>
              <Typography.Text strong style={{ fontWeight: '400', fontSize: '20px' }}>
                Создана:{' '}
                <Typography.Text style={{ fontWeight: '400', fontSize: '20px' }}>
                  {this.state.vacancy?.createdBy}
                </Typography.Text>
              </Typography.Text>
            </Space>
          </Card>
          <Card style={{ padding: 0, marginBottom: '20px', borderRadius: '20px' }}>
            <Typography.Title style={{ marginTop: 0 }}>Комментарий к кандидату</Typography.Title>
            <Input.TextArea
              value={vacancyApplicantStore.getVariable('comment')}
              onChange={(e) => {
                vacancyApplicantStore.updateVariable('comment', e.target.value);
              }}></Input.TextArea>
          </Card>
          <Card style={{ padding: 0, marginBottom: '20px', borderRadius: '20px' }}>
            <Typography.Title style={{ marginTop: 0 }}> История по кандидату</Typography.Title>
            <List
              loading={this.state.listLoadingStatus}
              itemLayout="horizontal"
              dataSource={this.state.history}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [5, 10, 20, 50],
              }}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    className={'alert-item'}
                    title={item.recruiter}
                    description={
                      <div>
                        {' '}
                        {<div> Статус: {item.stage.name} </div>}{' '}
                        {<div> Комментарий: {item.comment}</div>}
                      </div>
                    }
                  />
                  <div className={'alert-item-time'}>{moment(item.updatedAt).format('LLL')}</div>
                </List.Item>
              )}
            />
          </Card>
              </Col> </Row>
         
        </Col>
      </Row>
    );
  }
}
export default observer(withRouter(ApplicantData));
