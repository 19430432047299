import React from 'react';

import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Card, Space, Table, Upload } from 'antd';

import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../WithRouter';
import ActionButtons from '../components/ActionButtons';
import Title from 'antd/es/typography/Title';

class StagesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card style={{ margin: 20 }}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Title style={{ margin: 0 }}>Статусы</Title>
                  {/* <Space>
                    <Button
                      onClick={() => {
                        this.props.history.push(`create`);
                      }}
                      type="primary">
                            Добавить
                    </Button>

                    <Upload accept=".xml" fileList={[]}>
                      <Button type="primary" icon={<PlusSquareOutlined />}>
                        Скачать XML
                      </Button>
                    </Upload>

               
                  </Space> */}
                </div>
                <Table
                  dataSource={[
                    {
                      id: 1,
                      name: 'string',
                      description: 'string',
                    },
                    {
                      id: 2,
                      name: 'string',
                      description: 'string',
                    },
                    {
                      id: 3,
                      name: 'string',
                      description: 'string',
                    },
                  ]}
                  columns={[
                    {
                      dataIndex: 'name',
                      title: 'Название',
                    },
                    {
                      dataIndex: 'description',
                      title: 'описание',
                    },
                    {
                      dataIndex: 'id',
                      title: 'Действия',
                      width: 100,
                      render: (record, data) => {
                        return <ActionButtons id={data.id} />;
                      },
                    },
                  ]}
                />
              </>
            }
          />
          <Route path="/create" element={<h1>hello</h1>} />
          <Route path={'/view/:id'} element={<h1>hello</h1>} />

          <Route path={'/edit/:id'} element={<h1>hello</h1>} />
        </Routes>
      </Card>
    );
  }
}
export default withRouter(StagesPage);
