import React from 'react';

import { EyeOutlined, EditOutlined ,SearchOutlined} from '@ant-design/icons';
import { Button, Card, Space, Table, Col, Row, Input, Divider, message } from 'antd';
import { Tooltip } from 'antd';

import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../WithRouter';
import Title from 'antd/es/typography/Title';
import ApiDiContainer from '../apiService/apiDiContainer';
import CandidateData from '../components/CandidateData';
import { Link } from 'react-router-dom';

import { get, isEqual } from 'lodash';
import Highlighter from 'react-highlight-words';
import candidateStore from '../state/CandidateStore';
import { toJS } from 'mobx';
class CandidatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchText: '',
      searchedColumn: '',
    };
  }
  componentDidMount() {
    this.getAllData();
  }
  getAllData() {
    ApiDiContainer.ProxyApiAction.postAllApplicants({}).then((res) => {

      this.setState({ data: res.content });
    });
  }

    ///фильрты таблица (ФИЛЬТРЫ ДЛЯ ТАБЛИЦЫ ОБЩИЕ)
    getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Поиск`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}>
              Поиск
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Сбросить
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
        get(record, dataIndex)
          ? get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      },
      render: (text) =>
        isEqual(this.state.searchedColumn, dataIndex) ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#89c6ff', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
  
    handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
      });
    };
  
    handleReset = (clearFilters) => {
      clearFilters();
      this.setState({ searchText: '' });
    };
  render() {
    return (
      <Card style={{ margin: 20 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Title style={{ margin: 0 }}>Реестр кандидатов</Title>
          {/* <Space>
            <Button
              onClick={() => {
                this.props.history.push(`create`);
              }}
              type="primary">
              Добавить
            </Button>
          </Space> */}
        </div>

        <Routes>
          <Route
            path="/"
            element={
              <>
               <Button
               type='primary'
               style={{float:'right',marginBottom:'10px'}}
                onClick={() => {
                  candidateStore.resetVariables()
                  this.props.history.push(`create`,);
                }}

              >Создать кандидата</Button>
            
                <Table
                  dataSource={this.state.data}
                  columns={[
                    {
                      dataIndex: 'fio',
                      title: 'ФИО',
                      render: (text, record) => {
                        return (
                          <div>
                            <Link to={this.props.match.params.pathname + `/view/${record.id}`}>
                              <div>{record.lastName} {record.firstName} {record.middleName}</div>
                            </Link>
                          </div>
                        );
                      }
                    },
                    {
                      dataIndex: 'age',
                      title: 'Возраст',
                      sorter:(a,b)=>{
                         return a.age-b.age
                      },
                      
                      ...this.getColumnSearchProps('age')
                    },
                    {
                      dataIndex: 'phoneNumber',
                      title: 'Телефон',
                      ...this.getColumnSearchProps('phoneNumber')
                    },
                    {
                      dataIndex: 'email',
                      title: 'Email',
                      ...this.getColumnSearchProps('email')
                    },
                    {
                      dataIndex: 'applicantSource',
                      title: 'Источник',
                      ...this.getColumnSearchProps(['applicantSource','name']),
                      render: (text, record) => {

                        return (
                          <div>
                            <div>{record.applicantSource.name}</div>
                          </div>
                        );
                      },
                  
                    },
                    {
                      dataIndex: 'id',
                      title: 'Действия',
                      width: 100,
                      render: (record, data) => {

                        return <Space>
                          <Tooltip title="Просмотр">
                            <Link to={this.props.match.params.pathname + `/view/${data.id}`}>
                              <Button
                                onClick={() => {
                                  this.props.getItem && this.props.getItem(this.props.id);
                                }}
                                style={{ padding: 0 }}
                                type="primary"
                                size="small"
                                shape="circle"
                                ghost>
                                <EyeOutlined />
                              </Button>
                            </Link>
                          </Tooltip>
                          {/* <Tooltip title="Редактировать">
                            <Link to={this.props.match.params.pathname + `/edit/${data.id}`}>
                              <Button
                                onClick={() => {
                                  this.props.getItem && this.props.getItem(this.props.id);
                                }}
                                style={{ padding: 0 }}
                                type="primary"
                                size="small"
                                shape="circle"
                                ghost>
                                <EditOutlined />
                              </Button>
                            </Link>
                          </Tooltip> */}
                        </Space>;
                      },
                    },
                  ]}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: (event, r) => {
                        this.props.history.push(`view/${record.id}`);
                       
                      }, // double click row
                    };
                  }}
                />
              </>
            }
          />
          <Route path="/create" element={

<div>
            <div style={{display:'flex',justifyContent: 'flex-end'}}>
            <Button
                 style={{marginBottom:'10px'}}
                onClick={() => {
                  
                  ApiDiContainer.ProxyApiAction.addApplicant({

                    ...candidateStore.getObject(),
                    applicantSource:{
                      id:7
                    }
                  }).then((res)=>message.success('кандидат добавлен'))
                  this.props.history.push(`/candidates`,);
                }}

              >Создать</Button>
              
            </div>
            <CandidateData create />
          </div>

          } />

          <Route path={'/view/:id'} element={<div>
            <div style={{display:'flex',justifyContent: 'flex-end'}}>
            <Button
                 style={{marginBottom:'10px'}}
                onClick={() => {
                  this.props.history.push(`/candidates`,);
                }}

              >К списку кандидатов</Button>
              
            </div>
            <CandidateData />
          </div>} />

          <Route path={'/edit/:id'} element={<h1>hello</h1>} />
        </Routes>
      </Card>
    );
  }
}
export default withRouter(CandidatePage);
