import {
  CalendarOutlined,
  MessageOutlined,
  PoweroffOutlined,
  SearchOutlined,
  TeamOutlined,
  UserOutlined,
  AuditOutlined,
  BarsOutlined,
  SisternodeOutlined, FileExcelOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, Space, Typography } from 'antd';
import React from 'react';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import logotype from './Logo.svg'

import BadgeBell from './components/BadgeBell';
import TaskCalendar from './components/taskCalendar';

import { withRouter } from './WithRouter';
import ApplicantPage from './pages/ApplicantPage';
import PageOrgchart from './pages/PageOrgchart';
import StagesPage from './pages/StagesPage';
import UsersPage from './pages/UsersPage';
import ApiDiContainer from './apiService/apiDiContainer';
import currentUserStore from './state/CurrentUserStore';
import CandidatePage from './pages/CandidatePage';
import CalendarPage from './pages/CalendarPage';
import ChatPage from './pages/ChatPage';
import keycloak from './Keycloak';
import ReportPage from './pages/ReportPage';
const {Text} =Typography;
const { Header, Sider, Content } = Layout;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      userData: {}
    };
  }
  componentDidMount() {
    ApiDiContainer.ProxyApiAction.getCurrentUser().then((res) => {
      this.setState({ userData: res })

      currentUserStore.newVariables(res)
    })
  }
  render() {
    return (
      <Layout
        style={{
          height: '100vh',
          background: 'linear-gradient(180deg, rgba(0,55,145,1) 0%, rgba(255,255,255,1) 100%)',
        }}>
        <Sider
          style={{ borderTopRightRadius: '22px' }}
          trigger={null}
          collapsible
          theme="light"
        // collapsed={collapsed}
        // onClick={() => {
        //   setCollapsed(!collapsed);
        // }}
        >
          {/* {collapsed && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      marginBottom: 10,
                      marginTop: 10,
                    }}>
               
                  </div>
                )}
                {!collapsed && (
                  <div className="logo">
                 
                  </div>
                )} */}

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 10 }}>
            <img src={logotype} alt="logo" width={'50px'} />
          </div>

          <Menu
            // theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            items={[
              {
                key: '1',
                icon: <SisternodeOutlined />,
                label: 'Моя команда',

                onClick: () => {
                  this.props.navigate('/');
                  // setCollapsed(false);
                },
              },
              {
                key: '2',
                icon: <AuditOutlined />,
                label: 'Мои вакансии',
                onClick: () => {
                  this.props.navigate('/application');
                },
              },
              {
                key: '3',
                icon: <BarsOutlined />,
                label: 'База кандидатов',

                onClick: () => {
                  this.props.navigate('/candidates');
                  // setCollapsed(false);
                },
              },
              {
                key: '4',
                icon: <FileExcelOutlined />,
                label: 'Отчеты',

                onClick: () => {
                  this.props.navigate('/reports');
                  // setCollapsed(false);
                },
              },
              
              {
                key: '5',
                icon: <CalendarOutlined />,
                label: 'Календарь',
                onClick: () => {
                  this.props.navigate('/calendar');
                },
              },
              
           
             
              // {
              //   key: '13424',
              //   icon: <UserOutlined />,
              //   label: 'Личный кабинет new',
              //   onClick: () => {
              //     this.props.navigate('/NewUserPage');
              //     setCollapsed(false);
              //   },
              // },
              // {
              //   key: '13444',
              //   icon: <UserOutlined />,
              //   label: 'Просмотр профиля new',
              //   onClick: () => {
              //     this.props.navigate('/NewViewUserPage');
              //     setCollapsed(false);
              //   },
              // },
            ]}
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            style={{
              padding: 0,
              background: '#003791',
            }}>

            <Space style={{
              float: 'right', marginRight: '20px',
            }}>
             <Space>

             <Text style={{color:'white'}}> {currentUserStore.getVariable('lastName')}</Text>
             <Text style={{color:'white'}}>  {currentUserStore.getVariable('firstName')}
              </Text>
             </Space>

              <Link to={'/chatpage'}>
              <BadgeBell />
              </Link>
          

             
              <Link to={'/auth'}>
                <Button
                  shape={'circle'}
                  size="large"
                  icon={<PoweroffOutlined />}
                  onClick={() => {
                    keycloak.logout()
                  }}></Button>
              </Link>
            </Space>
          </Header>
          <Content
            style={{
              // margin: '24px 16px',
              // padding: 24,
              height: '100vh',
              background: '#F1F6FB',
            }}>
            <Routes>
              <Route path="/" element={<PageOrgchart />} />
              <Route path="/table" element={<h1>Какая-то таблица</h1>} />
              <Route path="/calendar" element={<CalendarPage />} />

              <Route path={'/stages/*'} element={<StagesPage />} />
              <Route path={'/application/*'} element={<ApplicantPage userData={this.state.userData} />} />
              <Route path={'/candidates/*'} element={<CandidatePage userData={this.state.userData} />} />

              <Route path={'/userspage/*'} element={<UsersPage />} />
              <Route path={'/chatpage/*'} element={<ChatPage />} />
              <Route path={'/reports/*'} element={<ReportPage />} />
            </Routes>
          </Content>
        </Layout>

        {/* <Modal
                title="Написать сообщение"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <NewMessage />
              </Modal> */}
      </Layout >
    );
  }
}
export default withRouter(App);
