import React from 'react';

import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FolderViewOutlined,
  SettingOutlined,
  SolutionOutlined,
  ScheduleOutlined,PlusSquareOutlined
} from '@ant-design/icons';

import { Breadcrumb, Button, Card, Divider, Space, Table, Tooltip, TreeSelect, Upload } from 'antd';

import Title from 'antd/es/typography/Title';
import FileSaver from 'file-saver';
import { Link, Route, Routes } from 'react-router-dom';
import { withRouter } from '../WithRouter';
import ApiDiContainer from '../apiService/apiDiContainer';
import ActionButtons from '../components/ActionButtons';
import ApplicantTable from '../components/ApplicantTable';
import VacancyData from '../components/VacancyData';
import ShowVacancy from '../components/ShowVacancy';
import AddRelationVacancy from '../components/AddRelationVacancy';
import moment from 'moment';
import currentUserStore from '../state/CurrentUserStore';
import { observer } from 'mobx-react';

class ApplicantPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedRowKeys: [],
      treeData:[],
      selectedArray:[]
    };
  }

  componentDidMount() {
    this.getAllVacancy({});
    this.getFilter();


  }

  getFilter(){
    ApiDiContainer.ProxyApiAction.getOrgchart().then((res)=>{
      this.setState({treeData:this.recursiveMake(res)})
      console.log(this.recursiveMake(res))
    })
  }
  recursiveMake(arr){
  return  arr.map((el)=>{
      if(el.children){
   

        return{
          ...el,
          value:el.value.title,
          title:el.value.title,
          children: this.recursiveMake(el.children),
        }
      }
      return{
        ...el,
        value:el.value.title,
        title:el.value.title,
      }
      
    })
  }
  getAllVacancy(filters) {
    ApiDiContainer.ProxyApiAction.getAllVacancy(filters).then((res) => {
      this.setState({ data: res.content });
    });
  }

  render() {
    const { SHOW_PARENT } = TreeSelect;
    const treeData = [
      {
        title: 'Отдел тестирования',
        value: '0-0',
        key: '0-0',
        children: [
          {
            title: 'Тестировщик',
            value: '0-0-0',
            key: '0-0-0',
          },
        ],
      },
      {
        title: 'Отдел разработки',
        value: '0-1',
        key: '0-1',
        children: [
          {
            title: 'Проектировщик',
            value: '0-1-0',
            key: '0-1-0',
          },
          {
            title: 'Разработчик',
            value: '0-1-1',
            key: '0-1-1',
          },
          {
            title: 'Макаревич',
            value: '0-1-2',
            key: '0-1-2',
          },
        ],
      },
    ];
    const tProps = {
     

      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: 'Отдел',
      style: {
        width: '100%',
      },
    };

    return (
      <Card style={{ margin: 20 }}>
      

      
      
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Title style={{ margin: 0 }}>Мои вакансии</Title>
         
        </div>
                <div style={{ display: 'flex', marginBottom: 20 }}>
                  
                  <TreeSelect {...tProps}
                    onChange={(e)=>{
                 this.setState({selectedArray:e})
                      // this.getAllVacancy({
                      //   filters:[{
                      //     key:"department",
                      //     fieldType:'STRING',
                      //     operator: 'IN',
                      //     values:e
                      //   }]
                      // })
                    }}
                  treeData={ this.state.treeData}
                  />

                  <Button
                    disabled={!this.state.selectedRowKeys.length > 0}
                    onClick={() => {
                      ApiDiContainer.ProxyApiAction.exportVacancy(this.state.selectedRowKeys).then(
                        (res) => {
                          console.log(res);
                          let blob = new Blob([res.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          });

                          FileSaver.saveAs(blob, 'vacancy.xlsx');
                        },
                      );
                    }}
                    type="primary"
                    style={{ marginLeft: 20 }}
                    icon={<PlusSquareOutlined />}>
                    Скачать Exel
                  </Button>
                </div>

                <Table
                  rowSelection={{
                    type: 'checkbox',

                    onChange: (selectedRowKeys, row) => {
                      this.setState({ selectedRowKeys });
                    },
                    selectedRowKeys: this.state.selectedRowKeys,
                    preserveSelectedRowKeys: false,
                  }}
                  rowKey={'id'}
                  dataSource={this.state.data
                    .filter((el)=>{
                      if(this.state.selectedArray){
                        this.state.selectedArray.some((elem)=> elem===el.department)
                      }
                      return true
                    }
                     
                    )}
                  columns={[
                    {
                      dataIndex: 'title',
                      title: 'Вакансия',
                    },
                    {
                      dataIndex: 'department',
                      title: 'Департамент',
                    },
                    {
                      dataIndex: 'numToHire',
                      title: 'Кол-во позиций',
                    },
                    {
                      dataIndex: ['status','title'],
                      title: 'Статус',
                    },
                    {
                      dataIndex: 'createdBy',
                      title: 'Инициатор',
                    },
                    {
                      dataIndex: 'lastUpdated',
                      title: 'Дата',
                      render:(e)=>moment(e).format('LLL')
                    },
                    {
                      dataIndex: 'id',
                      title: 'Действия',
                      width: 100,
                      render: (record, data) => { 
                       console.log(data)
return <Space>

{currentUserStore.getVariable('roles')?.some((el)=>el.code=='HRBP') && data?.status?.id==2 &&
<Tooltip title="Согласование HRBP">
            <Link to={this.props.match.params.pathname + `/view/${data.id}`}>
              <Button
                onClick={() => {
                  this.props.getItem && this.props.getItem(this.props.id);
                }}
                style={{ padding: 0 }}
                type="primary"
                size="small"
                shape="circle"
                ghost>
                <ScheduleOutlined />
              </Button>
            </Link>
          </Tooltip>




}

{currentUserStore.getVariable('roles')?.some((el)=>el.code=='RECRUITER') && data?.status?.id==3 &&currentUserStore.getVariable('id')==data?.recruiter &&



<Tooltip title="Добавление связей вакансии с кандидатом">
<Link to={this.props.match.params.pathname + `/edit/${data.id}`}>
  <Button
    onClick={() => {
      this.props.getItem && this.props.getItem(this.props.id);
    }}
    className={'actionGreenButton'}
    type="primary"
    size="small"
    shape="circle"
    ghost>
    <EditOutlined />
  </Button>
</Link>
</Tooltip>

}
                        </Space>
                      
                      },
                    },
                  ]}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: (event, r) => {
                        console.log('record',record)
                        this.props.history.push(`vacancy/${record.id}`);
                        this.setState({ vacancyId: record.id });
                      }, // double click row
                    };
                  }}
                />
              </>
            }
          />
          <Route path="/create" element={<h1>hello</h1>} />
          <Route path={'/vacancy/:id/*'} element={<ApplicantTable vacancyId={this.state.vacancyId} />} />
          <Route path={'/view/:id/*'} element={
        
        
       <>
        <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Title style={{ margin: 0 }}>Согласование HRBP вакансии</Title>
        {/* <Space>
          <Button
            onClick={() => {
              this.props.history.push(`create`);
            }}
            type="primary">
            Добавить
          </Button>
        </Space> */}
      </div>
      <ShowVacancy getAllVacancy={this.getAllVacancy}/>
       </>
        } />

          <Route path={'/edit/:id'} element={<AddRelationVacancy getAllVacancy={this.getAllVacancy}/>} />
        </Routes>
      </Card>
    );
  }
}
export default observer(withRouter(ApplicantPage));
