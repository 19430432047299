import React, { useRef } from 'react';
import { DecompositionTreeGraph } from '@ant-design/graphs';
import ModalVacancy from './ModalVacancy';
import ApiDiContainer from '../apiService/apiDiContainer';
import vacancyStore from '../state/VacancyStore';
import currentUserStore from '../state/CurrentUserStore';
import { message } from 'antd';



class MyChart extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {

      
    };
  }



  // Sample data for the DecompositionTreeGraph
 

 
 
render(){
  
  const data = {
    id: 'A0',
    value: {
      title: 'Структура',
   
    },
    children: this.props.data
  };



  const getChildren = async (e,b) => {
   
    const asyncData = await this.props.getChilden(e.id);
    console.log(asyncData)
    if(asyncData.length){
      return asyncData;
    }
  
  };

  const config = {

    data,
    layout:{
      direction: 'BT',
      getHGap: () => {
        // The vertical clearance of each node is used in conjunction with the getWidth return value
        return 400;
      },
      getWidth: () => {
        // The width of the node used to calculate the layout is recommended as size[0]
        return 80;
      },
    },
    getWidth: () => {
      // The width of the node used to calculate the layout is recommended as size[0]
      return 80;
    },
    getHGap: () => {
      // The vertical clearance of each node is used in conjunction with the getWidth return value
      return 300;
    },
    onReady: (graph) => {
      console.log(graph)
      graph.on('dblclick', (evt) => {
        console.log('tete',evt)
        if(evt.item){

          console.log(currentUserStore.getVariable('roles').some((el)=>el.code==='MANAGER'))
          if(currentUserStore.getVariable('roles').some((el)=>el.code==='MANAGER')){

            ApiDiContainer.ProxyApiAction.getOrgchartByGroup(evt.item._cfg.id).then((res)=>{
              vacancyStore.updateVariable('department',res.value.title)
              vacancyStore.updateVariable('createdBy',currentUserStore.getVariable('lastName'))
            })
          
  
            
            this.setState({isShow:true,
              clientX:
              Math.abs(evt.item._cfg.group.cfg.cacheCanvasBBox.maxX),
  
              clientY:Math.abs(evt.item._cfg.group.cfg.cacheCanvasBBox.maxY)
            })
          const item = evt.item;
          graph.setItemState(item, 'hover', true);
          }
          else{
            message.warning('у вас недостаточно прав для создания вакансии')
          }
        
        }

      });
  
    },
    autoFit: true,
    nodeCfg: {
      style:{
      
      

      },
      title:{
        containerStyle:{
          fill:'#003791d9'
        },
        items:{

       

        
        }
      },

     
      getChildren,
      autoWidth: true

      
    },
    
    markerCfg: (cfg) => {
      return {
        show: true,
      };
    },
    behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],

   
  };
  let iframeHeight = window.innerWidth-100 ;
  return (
    <div style={{height:'inherit'}}>
      <ModalVacancy
    isHidden={()=>{
      this.setState({isShow:false})
    }}
  isShow={this.state.isShow}
  />
    
      <DecompositionTreeGraph
     width={iframeHeight}
    
        {...config}
        layout={{
          type: 'mindmap',
        }}
        contextMenu={{
          id: 'graphContextMenu',
        }}
     
      
      />
        </div>
    
  );
}
};

export default MyChart;