import { BellOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, List, Popover, Tabs } from 'antd';
import moment from 'moment';
import React from 'react';
import ApiDiContainer from '../apiService/apiDiContainer';

const { TabPane } = Tabs;

class BadgeBell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countNitification: '',
      newNotification: [],
      listLoadingStatus: false,
      count:0,
    };
  }

  componentDidMount = () => {

    this.getNumNewNotification()
  };
  getNotification = () => {};

  readMessage = () => {};

  getNumNewNotification = () => {

    ApiDiContainer.ProxyApiAction.getNumOfNotification().then((res)=>{
this.setState({count:res})

    })
  };

  getNewNotification = () => {};

  render() {


    return (
      <Badge count={this.state.count} color="red">
      <Button shape={'circle'} size="large" icon={<BellOutlined />}></Button>
    </Badge>
    );
  }
}

export default BadgeBell;
