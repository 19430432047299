import { Button, Card, Divider, Select, Space, message } from 'antd';
import React from 'react';
import { withRouter } from '../WithRouter';
import ApiDiContainer from '../apiService/apiDiContainer';

import vacancyStore from '../state/VacancyStore';
import VacancyData from './VacancyData';
import moment from 'moment';
import { observer } from 'mobx-react';

class ShowVacancy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applicantUser: {},
      listOfRes:[]
    };
  }
  componentDidMount() {
    this.getCurrentApplicant();
    this.getRecruters()
  }
  getRecruters(){
    ApiDiContainer.ProxyApiAction.getRecruters().then((res)=>{
      this.setState({listOfRes:res.map((el)=>{

        return{
          ...el,
          title:el.lastName,
          value:el.id,
          label:`${el.lastName} ${el.firstName}`
        }
      })})
    })
  }
  getCurrentApplicant() {
    ApiDiContainer.ProxyApiAction.getVacancy(this.props.params.id).then((res) => {
      this.setState({ ...res });

      vacancyStore.newVariables(res);
    });
  }

  render() {
    console.log(this.state)
    return (
      <Card style={{ borderRadius: '20px' }}>
        <VacancyData />
      <Select style={{width:'100%'}} options={this.state.listOfRes}  onChange={(e,res)=>{

      vacancyStore.updateVariable('recruiter',e)

      }}  ></Select>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Space>
            <Button
              onClick={() => {


                if (!vacancyStore.checkRequired(['recruiter'])) {
                  message.info('Заполните обязательные поля');
                  return 
                }
                ApiDiContainer.ProxyApiAction.updateStatusVacancy({
                  id: vacancyStore.getVariable('id'),
                  recruiter: vacancyStore.getVariable('recruiter'),
                  status: {
                    id: 4,
                  },
                }).then(() => {
                  message.success('Вакансия отклонена');
                  this.props.history.push(`/application`);
                  this.props.getAllVacancy({})
                });
              }}>
              Отклонить
            </Button>
            <Button
              type="primary"
              onClick={() => {

                if (!vacancyStore.checkRequired(['recruiter'])) {
                  message.info('Заполните обязательные поля');
                  return 
                }
                ApiDiContainer.ProxyApiAction.updateStatusVacancy({
                  id: vacancyStore.getVariable('id'),
                  recruiter: vacancyStore.getVariable('recruiter'),
                  status: {
                    id: 3,
                  },
                }).then(() => {
                  message.success('Вакансия согласована');
                  this.props.history.push(`/application`);
                  this.props.getAllVacancy({})
                });
              }}>
              Согласовать
            </Button>
          </Space>
        </div>
      </Card>
    );
  }
}
export default observer(withRouter(ShowVacancy));
