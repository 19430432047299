import { makeAutoObservable } from 'mobx';



class CurrentUserStore {
  variables = new Map();
  selectors = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  updateVariable = (path, value) => {
    this.variables.set(path, value);
  };

  setSelectors = (key, selectors) => {
    // console.log(key, selectors);
    if (selectors === undefined) {
      this.selectors.delete(key);
      return;
    }
    this.selectors.set(key, selectors);
  };

  getSelectors = (key) => {
    return this.selectors.get(key) || undefined;
  };

  getVariable = (key) => {
    return this.variables.get(key) || undefined;
  };
  deleteVariable = (key) => {
    return this.variables.delete(key) || undefined;
  };

  resetSelectors = () => {
    this.selectors.clear();
  };

  resetSelector = (key) => {
    this.selectors.delete(key);
  };

  newVariables(data) {
    this.variables = new Map(Object.entries(data));
  }

  resetVariables() {
    this.variables = new Map();
  }

  resetVariable(variable) {
    this.variables.delete(variable);
  }

  getObject(withoutRequiredFields = false) {
    return {
      ...Object.fromEntries(this.variables),
      finder: this.getVariable('finder')?.id,
      assignOu: this.getVariable('assignOu')?.id,
      assignUser: this.getVariable('assignUser')?.id,
      analogSnu:this.getVariable('analogSnu')?.id,
      repeatSnu:this.getVariable('repeatSnu')?.id,
      analogGid:null,
      repeatGid:null
    };
  }

  checkRequired(requiredFields = []) {
    return requiredFields.reduce((accumulator, currentField) => {
      console.log(Boolean(this.getVariable(currentField)), currentField);
      return !Boolean(this.getVariable(currentField)) ? (accumulator = false) : accumulator;
    }, true);
  }
}

const currentUserStore = new CurrentUserStore();
export default currentUserStore;
