import { BellOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Col, DatePicker, Input, List, Modal, Popover, Row, Tabs, Typography, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { toJS } from 'mobx';
import moment from 'moment';
import React from 'react';
import vacancyApplicantStore from '../state/VacancyApplicantStore';
import { observer } from 'mobx-react';
import currentUserStore from '../state/CurrentUserStore';
import dayjs from 'dayjs';
import ApiDiContainer from '../apiService/apiDiContainer';
import { withRouter } from '../WithRouter';
const {Text}=Typography;

const { TabPane } = Tabs;

class ModalPlanInterview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countNitification: '',
      newNotification: [],
      listLoadingStatus: false,
      isModalOpen:true
    };
  }

  componentDidMount = () => {};
 

  render() {
console.log(toJS(vacancyApplicantStore))

    return (

    <Modal title="Запланировать интервью" open={this.props.showPlanInterview} onOk={()=>{
     

      this.props.isShow(false)
      ApiDiContainer.ProxyApiAction.changeStatusApplicant({
        applicant: {
          id: vacancyApplicantStore.getVariable("applicant")?.id
        },
        stage: {
          id: 15,
          name: 'Интервью',
        },
        vacancy: {
          id:vacancyApplicantStore.getVariable("vacancy")?.id
        },
        comment:vacancyApplicantStore.getVariable("comment")
      }).then((res)=>{

        ApiDiContainer.ProxyApiAction.createCalendarEvent({
          description:`Уважаемый ${vacancyApplicantStore.getVariable('applicant')?.firstName },
          Мы изучили Ваше резюме на позицию «${vacancyApplicantStore.getVariable("vacancy")?.title }» и пришли к выводу, что Ваше
          образование и опыт работы идеально подходят для
          этой должности. Чтобы обговорить все нюансы и
          ближе познакомиться, приглашаем Вас на
          собеседование, которое состоится  ${moment(vacancyApplicantStore.getVariable("dtPlanned"))}`,
          dtPlanned:vacancyApplicantStore.getVariable("dtPlanned"),
          meetingUrl:vacancyApplicantStore.getVariable("meetingUrl"),
          title:vacancyApplicantStore.getVariable("title"),
          vacancyApplicantEntity:{
            ...vacancyApplicantStore.getObject(),
            stage: {
              id: 15,
              name: 'Интервью',
            },
          }
        }).then(()=>{
          this.props.history.back();
          message.success(
            `Кандидат перешел на статус Интервью`,
          );
        })
      })
    

    }} onCancel={()=>{
      this.props.isShow(false)
    }}>
   <Row>

    <Col span={24}>
      <Text>Кому:   </Text><Text strong>{vacancyApplicantStore.getVariable('applicant')?.email }</Text>
    </Col>
    <Col span={24}>
      <Text>В копии</Text> <Text strong>{currentUserStore.getVariable('email') }</Text>
    </Col>
    <Col span={24}>
      <Text>Кандидат:</Text><Text strong> {vacancyApplicantStore.getVariable("applicant")?.lastName +' '+ vacancyApplicantStore.getVariable('applicant')?.middleName +' '+ vacancyApplicantStore.getVariable('applicant')?.firstName }</Text>
    </Col>
    <Col span={24}>
      <Text>Вакансия: </Text><Text strong>{vacancyApplicantStore.getVariable("vacancy")?.title }</Text>
    </Col>
    <Col span={24}>
      <Text>Дата</Text>
    </Col>
    <Col span={12}>
     <DatePicker value={vacancyApplicantStore.getVariable("dtPlanned")? dayjs(vacancyApplicantStore.getVariable("dtPlanned")) :undefined  }  onChange={(e,string)=>{
      console.log(string)
      vacancyApplicantStore.updateVariable('dtPlanned',string)
     }}/>
    </Col>
    <Col span={24}>
      <Text>Ссылка на конференцию</Text>

      <Input.TextArea value={ vacancyApplicantStore.getVariable("meetingUrl")}    onChange={(e)=> {
        vacancyApplicantStore.updateVariable('meetingUrl',e.target.value)
      }}></Input.TextArea>

<Text>Название встречи</Text>

<Input.TextArea value={ vacancyApplicantStore.getVariable("title")}    onChange={(e)=> {
  vacancyApplicantStore.updateVariable('title',e.target.value)
}}></Input.TextArea>

    </Col>
    <Col span={24}>
      <Text> Текст приглашения</Text>
      <TextArea autoSize disabled value={`Уважаемый ${vacancyApplicantStore.getVariable('applicant')?.firstName },
Мы изучили Ваше резюме на позицию «${vacancyApplicantStore.getVariable("vacancy")?.title }» и пришли к выводу, что Ваше
образование и опыт работы идеально подходят для
этой должности. Чтобы обговорить все нюансы и
ближе познакомиться, приглашаем Вас на
собеседование, которое состоится  ${moment(vacancyApplicantStore.getVariable("dtPlanned"))}`}></TextArea>
    </Col>
   


   </Row>
    </Modal>
    );
  }
}

export default observer( withRouter(ModalPlanInterview));
